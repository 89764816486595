import twoPresenting from "../../assets/images/pic/twopresenting.png";

export const LearnSection = () => {
  return (
    <>
      <section id="learn" className="p-5">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md" data-aos="fade-down-right" data-aos-delay="200">
              <img src={twoPresenting} className="img-fluid" alt="presentation illustration" />
            </div>
            <div id="learnInfo" className="col-md p-5" data-aos="fade-down-left" data-aos-delay="200">
              <h2>Learn in-demand language and frameworks</h2>
              <p id="lead" className="lead" style={{ fontSize: "1.375em" }}>
                Gain The Skills, Experience And Relationships You Need To Become A Full Stack Developer. By learning the
                most sought after languages and skill set!
              </p>
              <p id="utilSize" data-aos="zoom-out" data-aos-delay="200">
                We comprehensively cover Frontend and Backend Development. Frontend modules are Javascript, Typescript,
                React, html, css and Flutter. Backend covers: Nodejs, Postgres(relational databases),
                Mongodb(Non-relational), Redis (in-memory) databases and finaly React Native, Flutter for Mobile
                development
              </p>
              {/* <Link to="#" className="btn btn-success mt-3">
                <i className="bi bi-chevron-right"></i> Read More
              </Link> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
