import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Mui from "@mui/material";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import PhoneAndroidRoundedIcon from "@mui/icons-material/PhoneAndroidRounded";

import man from "../../assets/images/pic/mancoding.png";
// import { OAUTH_DOMAIN } from "../../constants";
import { updatesSliceActions } from "../../store/updates-slice";
import CourseUpdates from "./sub-components/course-updates";
import { RootState } from "../../store";
import { EditProfileModal } from "./edit-profile";
import { UpdatePhotoModal } from "./update-photo-modal";

export const ProfileCard = () => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showUpdatePhotoModal, setShowUpdatePhotoModal] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.user);

  const hideProfModal = () => setShowProfileModal(false);
  const showProfModal = () => setShowProfileModal(true);

  const userDetails = useSelector((state: RootState) => state.user.enrolleeData);
  console.log("UserDetails : ", userDetails);
  // const detailsUpdateHref = `${OAUTH_DOMAIN}`;

  const giveCourseUpdatesHandler = () => {
    dispatch(updatesSliceActions.isGivingUpdate());
  };

  const courseUpdateCont = userData.todayUpdate ? (
    <CourseUpdates />
  ) : (
    <div style={{ height: "100px" }}>
      <Mui.Button
        sx={{ mt: 3 }}
        disabled={!userData.currentTracker}
        onClick={giveCourseUpdatesHandler}
        variant="contained"
        color="info"
        size="large"
      >
        Give course update
      </Mui.Button>
    </div>
  );

  return (
    <>
      {/* {isLoading && <LoadingSpinner />} */}
      {showProfileModal && <EditProfileModal hideModal={hideProfModal} />}
      {showUpdatePhotoModal && <UpdatePhotoModal setShowUpdatePhotoModal={setShowUpdatePhotoModal} />}
      <Mui.Card elevation={1} sx={{ p: 0, pb: 2, border: "1px solid #C8C8C8", borderRadius: "1rem", overflow: "clip" }}>
        <Mui.CardActionArea>
          <Mui.CardMedia
            sx={{ height: 215, objectFit: "cover", maxHeight: 400, objectPosition: "top" }}
            image={userDetails?.imageUrl ? userDetails.imageUrl : man}
            title="profile"
          />
        </Mui.CardActionArea>
        <Mui.CardContent sx={{ p: 0 }}>
          <Mui.List sx={{ pb: 0 }}>
            <Mui.ListItem>
              <Mui.ListItemIcon>
                <AccountCircleRoundedIcon />
              </Mui.ListItemIcon>
              <Mui.ListItemText primary={`${userDetails?.fName} ${userDetails?.lName}`} />
            </Mui.ListItem>
            <Mui.Divider />
            <Mui.ListItem>
              <Mui.ListItemIcon>
                <EmailRoundedIcon />
              </Mui.ListItemIcon>
              <Mui.ListItemText primary={userDetails?.email} />
            </Mui.ListItem>
            <Mui.Divider />
            <Mui.ListItem>
              <Mui.ListItemIcon>
                <PhoneAndroidRoundedIcon />
              </Mui.ListItemIcon>
              <Mui.ListItemText primary={userDetails?.phoneNo} />
            </Mui.ListItem>
          </Mui.List>
        </Mui.CardContent>
        <Mui.Container sx={{ display: "flex", justifyContent: "space-between" }}>
          <Mui.Button variant="outlined" sx={{ p: "2px 10px" }} size="small" onClick={showProfModal}>
            Edit details
          </Mui.Button>
          <Mui.Button
            variant="outlined"
            // sx={{ p: "0 10px" }}
            size="small"
            onClick={() => setShowUpdatePhotoModal(true)}
          >
            Update profile pic
          </Mui.Button>
        </Mui.Container>
        <Mui.CardActions sx={{ justifyContent: "center" }}>
          <div className="row align-items-center px-0" style={{ width: "100%" }}>
            <div className="col text-center">{courseUpdateCont}</div>
          </div>
        </Mui.CardActions>
      </Mui.Card>
    </>
  );
};
