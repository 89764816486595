import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Card } from "@mui/material";

import CourseCompletedCard from "./course-completed-card";
import CourseInPipelineCard from "./course-in-pipeline-card";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export const CourseAccordion = () => {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Card
      elevation={1}
      sx={{
        borderRadius: { xs: "4px", sm: "4px", md: "4px 4px 4px 1rem", lg: "4px 4px 4px 1rem", xl: "4px 4px 4px 1rem" },
        mb: { xs: 1, sm: 1, md: 0, lg: 0, xl: 0 },
      }}
    >
      <Accordion
        sx={{ border: "2px solid #0288D1" }}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary sx={{ backgroundColor: "#0288D1" }} aria-controls="panel1d-content" id="panel1d-header">
          <Typography sx={{ fontSize: "1.05rem", textTransform: "uppercase", fontWeight: 400 }}>
            Completed courses
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#0289d152", padding: "1rem 0" }}>
          <CourseCompletedCard />
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          border: "2px solid #91CC75",
          borderRadius: {
            xs: "4px",
            sm: "4px",
            md: "4px 4px 4px 1rem",
            lg: "4px 4px 4px 1rem",
            xl: "4px 4px 4px 1rem",
          },
        }}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary sx={{ backgroundColor: "#91CC75" }} aria-controls="panel2d-content" id="panel2d-header">
          <Typography sx={{ fontSize: "1.05rem", textTransform: "uppercase", fontWeight: 400 }}>
            Upcoming courses
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#91cc7581", padding: "1rem 0" }}>
          <CourseInPipelineCard />
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};
