import { FormEvent, useState, ChangeEvent } from "react";
import { CardHeader, Typography, TextField, Box, Grid, Button } from "@mui/material";

import { ErrorType } from "../../models";
import { usePostTestimonialMutation } from "../../store/rtk";
import { NotificationModal } from "../common/notification-modal";

interface TestimonialModalProps {
  hideModal: () => void;
}

const initialState: {
  inputData: string;
  isValid: boolean;
  isTouched: boolean;
  error: null | string;
} = {
  inputData: "",
  isValid: false,
  isTouched: false,
  error: null,
};

export const TestimonialModal = ({ hideModal }: TestimonialModalProps) => {
  const [testimonialComment, setTestimonialComment] = useState(initialState);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const [postTestimonial] = usePostTestimonialMutation();

  const testimonialCommentChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setTestimonialComment({
        inputData: e.target.value,
        isValid: false,
        isTouched: true,
        error: "Please provide your testimonial",
      });
    } else {
      setTestimonialComment({
        inputData: e.target.value,
        isValid: true,
        isTouched: true,
        error: null,
      });
    }
  };

  const submitEventHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const data = {
      comment: formData.get("comment") as string,
    };
    // console.log("Data Obj: ", data);

    const sendRegDataToServer = async () => {
      try {
        const serverRes = (await postTestimonial(data)) as { data: string; error: ErrorType };
        if (serverRes.error) {
          setServerError(serverRes.error.data.error.message);
          setShowErrorBanner(true);
          return;
        }
        // console.log("Server res : ", serverRes);
        hideModal();
      } catch (err) {
        console.error("Post update Err : ", err);
        // setErrorMesg(`An error occured`);
        // setShowErrorBanner(true);
      }
    };
    sendRegDataToServer();
  };

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}
      <div className="modal fade show" style={{ display: "block", zIndex: 10000 }} tabIndex={-1}>
        <div
          className="modal-backdrop show"
          style={{ zIndex: "auto" }}
          onClick={() => {
            hideModal();
          }}
        ></div>
        <div className="modal-dialog modal-dialog-centered my-0">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="enrollLabel">
                <CardHeader
                  sx={{ pb: 0 }}
                  title={
                    <Typography
                      component="div"
                      sx={{ fontSize: "1.2rem", textTransform: "uppercase", textAlign: "center" }}
                    >
                      Testimonial
                    </Typography>
                  }
                ></CardHeader>
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  hideModal();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <form method="POST" onSubmit={submitEventHandler}>
                <Box
                  sx={{
                    "& .MuiTextField-root": { m: 1 },
                    justifyContent: "center",
                  }}
                  component="div"
                >
                  <Grid container component="div" sx={{ justifyContent: "center", px: 0 }}>
                    <TextField
                      onChange={testimonialCommentChangeHandler}
                      type="text"
                      name="comment"
                      // defaultValue={userData.todayUpdate.comment}
                      error={!testimonialComment.isValid && testimonialComment.isTouched}
                      fullWidth
                      helperText={<div>{testimonialComment.error}</div>}
                      label="Testimonial comment"
                      autoFocus={true}
                      multiline
                      rows={4}
                      id="outlined-size-normal"
                    />
                  </Grid>
                  <Grid container sx={{ justifyContent: "center", mt: 2 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="success"
                      size="large"
                      disabled={!testimonialComment.inputData}
                    >
                      submit
                    </Button>
                  </Grid>
                </Box>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
