import { configureStore, combineReducers } from "@reduxjs/toolkit";

import { userApi } from "./rtk/user-api";
import { enrollmentApi } from "./rtk/enrollment-api";
import { authApi } from "./rtk/auth-api";
import { userReducer } from "./user-slice";
import { chartReducer } from "./chart-slice";
import { authReducer } from "./auth-slice";
import { adminReducer } from "./admin-slice";
import { updatesReducer } from "./updates-slice";
import { sideBarReducer } from "./side-bar-slice";
import { errorReducer } from "./error-slice";
import { adminApi } from "./rtk/admin-api";

const rootReducer = {
  chart: chartReducer,
  user: userReducer,
  auth: authReducer,
  sideBar: sideBarReducer,
  adminSlice: adminReducer,
  updates: updatesReducer,
  error: errorReducer,
  [userApi.reducerPath]: userApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [enrollmentApi.reducerPath]: enrollmentApi.reducer,
};

const combined = combineReducers(rootReducer);

export const store = configureStore({
  reducer: combined,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(adminApi.middleware)
      .concat(enrollmentApi.middleware)
      .concat(userApi.middleware)
      .concat(authApi.middleware),
});

//NOTE: This state should not be exported. use store.getState() where necessary.
const state = store.getState();
export type RootState = typeof state;
export type AppDispatchType = typeof store.dispatch;
