import { useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";
import { RootState } from "../../../store";

const CourseInPipelineCard = () => {
  const userData = useSelector((state: RootState) => state.user);

  return (
    <>
      {" "}
      <Scrollbars style={{ height: 210 }}>
        <ul>
          {userData.remainingCourses.map(upComingCourse => (
            <li key={upComingCourse?.id} className="list-item" style={{ fontSize: "1.05rem" }}>
              {upComingCourse.name}
            </li>
          ))}
        </ul>
        {userData.remainingCourses.length === 0 && (
          <div style={{ display: "flex", alignContent: "center", justifyContent: "center", fontSize: "1.08rem" }}>
            All courses have been completed
          </div>
        )}
      </Scrollbars>
    </>
  );
};

export default CourseInPipelineCard;
