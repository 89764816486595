import { useState, FormEvent } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import { ImageFileUploader } from "../common/image-file-uploader";
import { genericErrorMessg } from "../../util";
import { usePostStudentDetailsUpdateMutation } from "../../store/rtk/user-api";
import { NotificationModal } from "../common/notification-modal";
import { LoadingSpinner } from "../common/loading-spinner";
import { ErrorType, TokenResp } from "../../models";
import { RootState } from "../../store";

interface UpdatePhotoModalProps {
  setShowUpdatePhotoModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdatePhotoModal = ({ setShowUpdatePhotoModal }: UpdatePhotoModalProps) => {
  const [imageUrl, setImageUrl] = useState("");
  const [imageIsUploaded, setImageIsUploaded] = useState(false);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const navigate = useNavigate();
  const [updateUserDetails, { isLoading }] = usePostStudentDetailsUpdateMutation();

  const fetchFormState = (imagePath: string, uploadState: boolean) => {
    setImageUrl(imagePath);
    setImageIsUploaded(uploadState);
  };

  const accessToken = useSelector((state: RootState) => state.auth.accessToken) || localStorage.getItem("accessToken");
  if (!accessToken) {
    navigate("/", { state: { tokenExpired: true } });
  }

  const hideModal = () => {
    setShowUpdatePhotoModal(false);
  };

  const submitEventHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = { imageUrl };

    const sendDataToServer = async () => {
      try {
        const serverRes = (await updateUserDetails(data)) as { data: TokenResp; error: ErrorType };
        if (serverRes.error) {
          setServerError(serverRes.error.data.error.message);
          setShowErrorBanner(true);
          return;
        }

        hideModal();
      } catch (err) {
        setServerError(genericErrorMessg);
        setShowErrorBanner(true);
      }
    };

    sendDataToServer();
  };

  return (
    <>
      <div
        className="modal fade show"
        style={{ display: "block", zIndex: 1000000, zoom: "1.1" }}
        tabIndex={-1}
        role="dialog"
      >
        {serverError && showErrorBanner && (
          <NotificationModal
            onClose={() => {
              setShowErrorBanner(false);
              setServerError(null);
            }}
            message={serverError as string}
            show={true}
            success={false}
          />
        )}
        <div
          className="modal-backdrop show"
          onClick={() => {
            hideModal();
          }}
        ></div>
        {isLoading && <LoadingSpinner />}
        <div
          className="modal-dialog modal-dialog-centered changePasswordModal"
          style={{ borderColor: "transparent", maxWidth: "35rem" }}
        >
          <div
            className="modal-body"
            style={{
              zIndex: 2000,
              // textAlign: "center",
              margin: "auto",
            }}
          >
            <div
              className="modal-content "
              style={{
                overflow: "hidden",
                backgroundColor: "#fff",
              }}
            >
              <div
                className="modal-header"
                style={{
                  justifyContent: "center",
                }}
              >
                <h2
                  className="text-center fs-4 modal-title changePasswordHeader"
                  style={{
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  Upload profile image
                </h2>
              </div>
              <div className="container modal-body px-5 changePasswordContainer" style={{ textAlign: "initial" }}>
                <form action="" method="post" onSubmit={submitEventHandler}>
                  <ImageFileUploader fetchFormState={fetchFormState} />
                  <input name="imageFilePath" type="text" hidden={true} defaultValue={imageUrl} />
                  <div className="d-grid gap-2 col-6 mx-auto pt-2 pb-2 px-0">
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={!imageIsUploaded}
                      style={{
                        width: "100%",
                      }}
                    >
                      set as profile image
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
