import { useSelector } from "react-redux";
import { Box, Card, CardHeader, Typography } from "@mui/material";

import { RootState } from "../../../store";

// import { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { chartSliceActions } from "../../../store/chart-slice";
// import { getUpdatedChartData } from "../../../util";

const CourseOptions = () => {
  const userData = useSelector((state: RootState) => state.user);
  // const [selectedValue, setSelectedValue] = useState<string | null>(null);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (userData.currentTracker && userData.currentTracker.course.name) {
  //     setSelectedValue(userData.currentTracker.course.name);
  //   }
  // }, [userData.currentTracker]);

  // const upcomingCoursesForStudent =
  //   userData.remainingCourses.length > 0 ? userData.remainingCourses.map(course => course.name) : [];
  // const completedCoursesForStudent =
  //   userData.completedTrackers.length > 0 ? userData.completedTrackers.map(({ course }) => course.name) : [];
  // const courses = [...upcomingCoursesForStudent, ...completedCoursesForStudent];

  // const handleSelectChange = (event: React.SyntheticEvent, value: string | null) => {
  //   setSelectedValue(value as string);
  //   const updatedChartData = getUpdatedChartData(`${value}`, userData);
  //   dispatch(chartSliceActions.setChartData(updatedChartData));
  // };

  return (
    <>
      {/* <Autocomplete
        className="mb-3"
        onChange={handleSelectChange}
        value={selectedValue}
        disablePortal
        sx={{ borderRadius: 0 }}
        size="medium"
        id="combo-box-demo"
        options={courses}
        renderInput={params => <TextField {...params} label="Select a course whose progress you wish to see" />}
      /> */}
      <Card className="mb-3">
        <CardHeader
          sx={{ p: 0 }}
          title={
            <Box sx={{ display: "flex", justifyContent: "space-around", pt: { xs: 2, sm: 2, lg: 0, xl: 0 } }}>
              <Typography sx={{ fontSize: "1.05rem", textTransform: "uppercase", textAlign: "center" }}>
                Course in chart :
              </Typography>
            </Box>
          }
        ></CardHeader>
        <Typography sx={{ textAlign: "center", pb: 1 }}>{userData.currentTracker?.course.name || ""}</Typography>
      </Card>
    </>
  );
};

export default CourseOptions;
