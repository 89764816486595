import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Paper, Grid, Button, Typography, Link } from "@mui/material";
import axios from "axios";
import { getBody, DOMAIN, origin } from "../../constants";
import { NotificationModal } from "../../components";
import logo from "../../assets/images/Beren_opacity(50).png";
import { ReactComponent as Illustration } from "../../assets/svg/Authentication_Monochromatic.svg";
import { ScaleLoader } from "react-spinners";
// import bcrypt from "bcryptjs";
// import { useSendLogsMutation } from "../../store/admin-api";
// import { sendLogBody } from "../../util/send-log";

export const Login = () => {
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const [err, setErr] = useState<null | string>(null);
  const location = useLocation();
  // const [sendLogs] = useSendLogsMutation();

  // console.log("=============== IN LOGIN PAGE ========================");

  useEffect(() => {
    if (location.state && location.state?.tokenExpired) {
      setShowErrorBanner(true);
      setServerError("Your session has expired. Please log in again.");
    } else if (location.state && location.state?.err) {
      setShowErrorBanner(true);
      setServerError("An error occurred");
    } else if (location.state && location.state?.noAccount) {
      setShowErrorBanner(true);
      setServerError(`${location.state?.noAccount}`);
      // setServerError("Sorry, you have no permission to access our services. Please enroll first");
    } else if (location.state && location.state?.unauthorized) {
      setShowErrorBanner(true);
      setServerError("Unauthorized access. Log in with appropriate account");
    }
  }, [location.state]);

  useEffect(() => {
    if (err) {
      const timeout = setTimeout(() => {
        setErr(null);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [err]);

  const handleLogin = async () => {
    const getOauthUrl = DOMAIN + "/init-data";
    try {
      // 1. Get init data - clientId && oauthUrl
      setIsLoading(true);
      const response = await axios.get(getOauthUrl);
      const resData = response.data;

      if (resData.error) {
        // throw resData.error;
        setIsLoading(false);
        setErr("An error occurred");
        return;
      }

      const headers = { "Client-Auth": `${resData.clientId}`, "Content-Type": "application/json" };

      // at this point in time, clientId has not been set to local storage so we just pass it in.
      const body = getBody(resData.clientId);

      // 2. Generate codeChallenge
      const hashStringUrl = resData.oauthUrl + "/hash";
      const hashResp = await axios.post(hashStringUrl, { text: body.codeVerifier }, { headers });
      const hashResData = hashResp.data;

      if (hashResData.error) {
        // throw resData.error;
        setIsLoading(false);
        setErr("An error occurred");
        return;
      }

      localStorage.setItem("oauth", resData.oauthUrl);
      localStorage.setItem("clientId", resData.clientId);
      localStorage.setItem("codeChallenge", hashResData);
      // localStorage.setItem("codeChallenge", body.codeChallenge);

      // const validChallenge = await bcrypt.compare(body.codeVerifier!, body.codeChallenge!);

      // await sendLogs(sendLogBody(`Valid code challenge 🌞BEFORE🌞 redirection : ${validChallenge}`));
      // await sendLogs(sendLogBody(`code verifier 🌞BEFORE🌞 redirection : ${body.codeVerifier}`));
      // await sendLogs(sendLogBody(`code challenge 🌞BEFORE🌞 redirection : ${body.codeChallenge}`));

      // Navigate to consent screen
      const url = `${resData.oauthUrl}/redirect?redirectURI=${body.redirectURI}&clientId=${resData.clientId}&codeChallenge=${hashResData}&origin=${origin}`;
      // const url = `http://localhost:3001/redirect?redirectURI=${body.redirectURI}&clientId=${resData.clientId}&codeChallenge=${hashResData}&origin=${origin}`;

      window.location.assign(url);
    } catch (err) {
      setErr("An error occured");
      setIsLoading(false);
    }
  };

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}
      <Box
        sx={{
          background: "linear-gradient(180deg, #EAF4FA 0%, #60A2C9 100%);",
          height: { md: "100vh", lg: "100vh", xl: "100vh" },
          py: { xs: 7, sm: 7 },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            borderRadius: "1rem",
            width: "max-content",
            height: "max-content",
            alignSelf: "center",
            maxWidth: { xs: 360, sm: 500, md: 500, lg: 550 },
            px: { sm: 2, md: 2 },
            pt: 2,
          }}
        >
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
              {<Illustration style={{ maxHeight: 350, maxWidth: 350 }} />}
            </Grid>
            <Grid item xs={12} my={2} sx={{ display: "flex", justifyContent: "center" }}>
              {isLoading ? (
                <>
                  <ScaleLoader color="#36d7b7" margin={3} radius={12} />
                </>
              ) : (
                <Button
                  variant="contained"
                  size="medium"
                  sx={{
                    backgroundColor: err ? "red" : "#66ccff",
                    borderRadius: "100px",
                    transition: "0.3s",
                    px: 3,
                    ":hover": {
                      opacity: 1,
                      backgroundColor: "#66ccff",
                      scale: "1.05",
                      transition: "0.3s",
                    },
                    ":disabled": {
                      backgroundColor: "rgb(230, 62, 33)",
                      color: "#fff",
                    },
                  }}
                  disabled={err !== null}
                  startIcon={<img src={logo} height={35} width={35} alt="logo" />}
                  onClick={handleLogin}
                >
                  {err ? "An error occurred, try again in 3 seconds" : "Sign in with Beren, LLC"}
                </Button>
              )}
            </Grid>
            <Grid
              item
              mt={2}
              mb={4}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  maxWidth: 450,
                  fontSize: { sm: "0.8rem", md: "1rem" },
                  color: "#53585A",
                }}
                textAlign={"center"}
              >
                By clicking “sign in with Beren, LLC” above you acknowledge that you have read and understood the{" "}
                <Link href="https://kberen.com/policy" underline="none">
                  terms and conditions
                </Link>{" "}
                &{" "}
                <Link href="https://kberen.com/policy" underline="none">
                  privacy policy
                </Link>
                .
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};
