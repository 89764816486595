import { useEffect, useState, useRef } from "react";
import { ClockLoader } from "react-spinners";
import Calendar from "react-calendar";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import "../../assets/css/calendar.css";
import { TileContentFunc } from "react-calendar/dist/cjs/shared/types";
import { AttendanceStatus, ActiveStudents, GetAttendanceResp, ErrorType } from "../../models";
import { NotificationModal } from "../common/notification-modal";
import { useGetAttendanceMutation } from "../../store/rtk/admin-api";
import { isDateInArray } from "../../util";

interface ViewAttendanceCalenderProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMember: ActiveStudents | null;
}

const today = new Date();

export const ViewAttendanceCalender = ({ show, setShow, selectedMember }: ViewAttendanceCalenderProps) => {
  const [getAttendance, { isLoading, error }] = useGetAttendanceMutation();
  const [year, setYear] = useState<number>(today.getUTCFullYear());
  const [month, setMonth] = useState<number>(today.getUTCMonth());
  const attendanceHasBeenFetched = useRef<boolean>(false);
  const [presentDays, setPresentDays] = useState<[] | Date[]>([]);
  const [absentDays, setAbsentDays] = useState<[] | Date[]>([]);
  const [pendingDays, setPendingDays] = useState<[] | Date[]>([]);
  const [excusedDays, setExcusedDays] = useState<[] | Date[]>([]);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const startDate = new Date(selectedMember!.enrollmentDate);

  useEffect(() => {
    if (error) {
      (attendanceHasBeenFetched as { current: boolean }).current = true;
      setServerError(`${error}`);
      setShowErrorBanner(true);
    }
  }, [error]);

  useEffect(() => {
    const enrollmentDate = new Date(selectedMember!.enrollmentDate);
    if (!attendanceHasBeenFetched.current && month >= enrollmentDate.getUTCMonth()) {
      const sendDataToServer = async () => {
        try {
          const data = { enrollmentId: selectedMember!.id, month: month, year: year };
          const serverRes = (await getAttendance(data)) as { data: GetAttendanceResp; error: ErrorType };

          if (serverRes.error) {
            (attendanceHasBeenFetched as { current: boolean }).current = true;
            return;
          }

          // console.log("Server Res : ", serverRes.data);
          const presDaysObjArr = serverRes.data.filter(dates => dates.attendanceFlag === "present");
          const presDays = presDaysObjArr.map(day => new Date(day.date));
          setPresentDays(presDays);

          const absDaysObjArr = serverRes.data.filter(dates => dates.attendanceFlag === "absent");
          const absDays = absDaysObjArr.map(day => new Date(day.date));
          setAbsentDays(absDays);

          const excDaysObjArr = serverRes.data.filter(dates => dates.attendanceFlag === "excused");
          const excDays = excDaysObjArr.map(day => new Date(day.date));
          setExcusedDays(excDays);

          const penDaysObjArr = serverRes.data.filter(dates => dates.attendanceFlag === "pending");
          const penDays = penDaysObjArr.map(day => new Date(day.date));
          setPendingDays(penDays);

          (attendanceHasBeenFetched as { current: boolean }).current = true;
        } catch (err) {
          (attendanceHasBeenFetched as { current: boolean }).current = true;
          console.error("Fetching attendance Error : ", err);
          setServerError(`Unable to fetch attendance`);
          setShowErrorBanner(true);
        }
      };

      sendDataToServer();
    }
  }, [getAttendance, selectedMember, year, month]);

  // const transformedExcusedDates = excusedDates.map(date => {
  //   // Set the time component to midnight for both dates
  //   date.setHours(0, 0, 0, 0);

  //   return date.getTime();
  // });

  const resetDates = (dates: Date[]) => {
    return dates.map(date => {
      // Set the time component to midnight for both dates
      date.setHours(0, 0, 0, 0);

      return date.getTime();
    });
  };

  const highlightTile: TileContentFunc = ({ date, view }) => {
    // Only highlight tiles in monthly view
    date.setHours(0, 0, 0, 0);

    if (view === "month") {
      const isPresent = isDateInArray(presentDays, date);
      const isAbsent = isDateInArray(absentDays, date);
      const isExcused = isDateInArray(excusedDays, date);
      const isPending = isDateInArray(pendingDays, date);

      // console.log("pendingDays : ", isPending);
      // Check if date is in array of dates to highlight

      if (isAbsent) {
        // Return custom JSX to highlight tile
        return (
          <p
            style={{
              display: "block",
              padding: "0.5rem",
              fontSize: "0.87rem",
              color: "#C52227",
            }}
          >
            {AttendanceStatus.ABSENT}
          </p>
        );
      } else if (isPresent) {
        return (
          <p
            style={{
              display: "block",
              padding: "0.5rem",
              fontSize: "0.87rem",
              color: "#038A22",
            }}
          >
            {AttendanceStatus.PRESENT}
          </p>
        );
      } else if (isLoading) {
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0.5rem",
              fontSize: "0.87rem",
              color: "#36d7b7",
              textAlign: "center",
              width: "100%",
            }}
          >
            <ClockLoader color="#36d7b7" size={25} />
          </p>
        );
      } else if (date < startDate) {
        return (
          <p
            style={{
              display: "block",
              padding: "0.5rem",
              fontSize: "0.87rem",
              color: "#000",
              textAlign: "center",
            }}
          >
            ---
          </p>
        );
      } else if (isExcused) {
        return (
          <p
            style={{
              display: "block",
              padding: "0.075rem",
              fontSize: "0.66rem",
              color: "#E28D0E",
            }}
          >
            {AttendanceStatus.EXCUSED}
          </p>
        );
      } else if (!isLoading || isPending) {
        return (
          <p
            style={{
              display: "block",
              padding: "0.075rem",
              fontSize: "0.66rem",
              color: "#427479",
            }}
          >
            {AttendanceStatus.PENDING}
          </p>
        );
      }
    }
  };

  const setTileClassName = ({ date }: { date: Date }) => {
    // Set the time component to midnight for both dates
    date.setHours(0, 0, 0, 0);

    const isPresent = resetDates(presentDays).includes(date.getTime());
    const isAbsent = resetDates(absentDays).includes(date.getTime());
    const isExcused = resetDates(excusedDays).includes(date.getTime());

    if (isAbsent) {
      return "absent";
    } else if (isPresent) {
      return "present";
    } else if (date < startDate) {
      return "unaccounted";
    } else if (isExcused) {
      return "excused";
    } else if (isLoading) {
      return "loading";
    } else {
      return "pending";
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}
      <Dialog open={show} onClose={handleClose}>
        <DialogContent sx={{ pt: 2, px: 1, width: "100%" }}>
          <Calendar
            className={["calendar-tile-mth"]}
            // minDetail="month"
            //  The hebrew calendartype works as either Hebrew or hebrew in diff computers
            calendarType="hebrew"
            tileContent={highlightTile}
            showNeighboringMonth={false}
            tileClassName={setTileClassName}
            onActiveStartDateChange={({ action, activeStartDate, value, view }) => {
              // console.log(`Tile has changed ${activeStartDate!.getMonth()} ${activeStartDate!.getFullYear()}`);
              (attendanceHasBeenFetched as { current: boolean }).current = false;
              setMonth(activeStartDate!.getMonth());
              setYear(activeStartDate!.getFullYear());
            }}
            defaultValue={new Date()}
            // value={value as Date}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
