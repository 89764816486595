import { Helmet } from "react-helmet";
import "../../assets/css/progress.css";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { ProfileCard } from "./profile-card";
import { UpdateLectureModal } from "./update-lecture-modal";
import { CourseProgressSection } from "./course-progress-section";
import { CourseUpdatesModal } from "./sub-components/course-updates-modal";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

interface DashboardDesktopProps {
  editingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export const DashboardDesktop = ({ editingState }: DashboardDesktopProps) => {
  const isGivingUpdate = useSelector((state: RootState) => state.updates.isGivingUpdate);
  const isEditingUpdate = useSelector((state: RootState) => state.updates.isEditing);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  const shouldGiveUpdatesState = useState(false);

  return (
    <>
      <Helmet>
        <title>Personal dashboard</title>
      </Helmet>
      <div
        className="container"
        data-aos="fade-in"
        style={{ color: "#3a3b45", marginTop: "2.65rem", marginBottom: "2.65rem" }}
      >
        <div className="row">
          {/* ---------------- profile card ---------- */}
          <div className="col-lg-4 profileCard">
            {windowWidth < 480 && (
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <AccountCircleOutlinedIcon sx={{ mr: 2, fontSize: "1.75rem" }} />
                <h2 style={{ textTransform: "uppercase", textAlign: "center", fontWeight: "bolder" }}>Profile</h2>
              </div>
            )}
            <ProfileCard />
          </div>

          {/* ------------- Course Progress Section --------------- */}
          <div className="col-lg-8">
            <CourseProgressSection shouldGiveUpdatesState={shouldGiveUpdatesState} editingState={editingState} />
          </div>
        </div>
      </div>

      {/* ---------------- modal update lecture--------------------- */}
      <UpdateLectureModal />

      {(isGivingUpdate || isEditingUpdate) && <CourseUpdatesModal />}
    </>
  );
};
