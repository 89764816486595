import { useState, ChangeEvent, FormEvent } from "react";

import { NotificationModal } from "../common/notification-modal";
import { useEditCourseMutation } from "../../store/rtk/admin-api";
import { LoadingSpinner } from "../common/loading-spinner";
import { ErrorType } from "../../models";

interface EditCourseModalProps {
  setShowEditCourseModal: React.Dispatch<React.SetStateAction<boolean>>;
  courseNameInput: string | undefined;
  courseAuthorInput: string | undefined;
  lectureNoInput: number | undefined;
  courseId: number | undefined;
  completionDays: number | undefined;
}

const initialState = {
  inputData: "",
  isValid: true,
  isTouched: true,
  error: "",
};

const isNoRegExp = /^\d+$/;

export const EditCourseModal = ({
  courseAuthorInput,
  courseNameInput,
  lectureNoInput,
  courseId,
  completionDays,
  setShowEditCourseModal,
}: EditCourseModalProps) => {
  const [courseName, setCourseName] = useState(initialState);
  const [courseAuthor, setCourseAuthor] = useState(initialState);
  const [lectureNo, setLectureNo] = useState(initialState);
  const [completionTime, setCompletionTime] = useState(initialState);

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);

  const [editCourse, { isLoading }] = useEditCourseMutation();

  const hideModal = () => {
    setShowEditCourseModal(false);
  };

  const courseNameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const trimmedInput = e.target.value.trim();
    if (trimmedInput.length === 0) {
      setCourseName({
        inputData: e.target.value,
        isValid: false,
        isTouched: true,
        error: "Course name should not be empty",
      });
    } else {
      setCourseName({
        inputData: e.target.value,
        isValid: true,
        isTouched: true,
        error: "",
      });
    }
  };

  const courseAuthorChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const trimmedInput = e.target.value.trim();
    if (trimmedInput.length === 0) {
      setCourseAuthor({
        inputData: e.target.value,
        isValid: false,
        isTouched: true,
        error: "Course author should not be empty",
      });
    } else {
      setCourseAuthor({
        inputData: e.target.value,
        isValid: true,
        isTouched: true,
        error: "",
      });
    }
  };

  const lectureNoChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const trimmedInput = e.target.value.trim();
    if (trimmedInput.length === 0) {
      setLectureNo({
        inputData: e.target.value,
        isValid: false,
        isTouched: true,
        error: "Lecture number should not be empty",
      });
    } else if (!isNoRegExp.test(trimmedInput)) {
      setLectureNo({
        inputData: e.target.value,
        isValid: false,
        isTouched: true,
        error: "Lecture number must be a digit",
      });
    } else {
      setLectureNo({
        inputData: e.target.value,
        isValid: true,
        isTouched: true,
        error: "",
      });
    }
  };

  const submitEventHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const data = {
      id: courseId as number,
      name: formData.get("courseName") as string,
      author: formData.get("courseAuthor") as string,
      completionDays: Number(formData.get("completionTime")),
      totalLecNo: Number(formData.get("lectureTotal")),
    };

    const sendDataToServer = async () => {
      const serverRes = (await editCourse(data)) as { data: string; error: ErrorType };
      if (serverRes.error) {
        setServerError(serverRes.error.data.error.message);
        setShowErrorBanner(true);
        return;
      }

      hideModal();
    };

    sendDataToServer();
  };

  const completionTimeChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const trimmedInput = e.target.value.trim();
    if (trimmedInput.length === 0) {
      setCompletionTime({
        inputData: e.target.value,
        isValid: false,
        isTouched: true,
        error: "Expected completion time should not be empty",
      });
    } else if (!isNoRegExp.test(trimmedInput)) {
      setCompletionTime({
        inputData: e.target.value,
        isValid: false,
        isTouched: true,
        error: "Expected completion time must be a digit",
      });
    } else {
      setCompletionTime({
        inputData: e.target.value,
        isValid: true,
        isTouched: true,
        error: "",
      });
    }
  };

  const courseNameClasses =
    courseName.isValid === false && courseName.isTouched === true ? "form-control  is-invalid" : "form-control";
  const courseAuthorClasses =
    courseAuthor.isValid === false && courseAuthor.isTouched === true ? "form-control  is-invalid" : "form-control";
  const lectureNoClasses =
    lectureNo.isValid === false && lectureNo.isTouched === true ? "form-control  is-invalid" : "form-control";
  const completionTimeClasses =
    completionTime.isValid === false && completionTime.isTouched === true ? "form-control  is-invalid" : "form-control";

  const formIsValid = courseName.isValid && courseAuthor.isValid && lectureNo.isValid && completionTime.isValid;
  return (
    <>
      {isLoading && <LoadingSpinner />}
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}
      <div className="modal fade show" style={{ display: "block", zIndex: 10000 }} tabIndex={-1}>
        <div
          className="modal-backdrop show"
          style={{ zIndex: "auto" }}
          onClick={() => {
            hideModal();
          }}
        ></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit course</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  hideModal();
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form method="post" onSubmit={submitEventHandler}>
                <div className="mb-2">
                  <label htmlFor="courseName" className="col-form-label">
                    Course Name:
                  </label>
                  <input
                    type="text"
                    name="courseName"
                    className={courseNameClasses}
                    id="courseName"
                    onChange={courseNameChangeHandler}
                    aria-describedby="courseNameFeedback"
                    defaultValue={courseNameInput}
                    // value={courseName.inputData}
                    required
                  />
                  {courseName.error && (
                    <div id="courseNameFeedback" className="invalid-feedback">
                      {courseName.error}
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <label htmlFor="courseAuthor" className="col-form-label">
                    Course Author:
                  </label>
                  <input
                    type="text"
                    name="courseAuthor"
                    className={courseAuthorClasses}
                    id="courseAuthor"
                    onChange={courseAuthorChangeHandler}
                    aria-describedby="courseAuthorFeedback"
                    defaultValue={courseAuthorInput}
                    // value={courseAuthor.inputData}
                    required
                  />
                  {courseAuthor.error && (
                    <div id="courseAuthorFeedback" className="invalid-feedback">
                      {courseAuthor.error}
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <label htmlFor="lectureTotal" className="col-form-label">
                    Lecture Total:
                  </label>
                  <input
                    type="number"
                    name="lectureTotal"
                    className={lectureNoClasses}
                    id="firstName"
                    onChange={lectureNoChangeHandler}
                    aria-describedby="lectureTotalFeedback"
                    defaultValue={lectureNoInput}
                    // value={lectureNo.inputData}
                    required
                  />
                  {lectureNo.error && (
                    <div id="lectureTotalFeedback" className="invalid-feedback">
                      {lectureNo.error}
                    </div>
                  )}
                </div>
                <div className="mb-2">
                  <label htmlFor="completionTime" className="col-form-label">
                    Expected completion time:
                  </label>
                  <input
                    type="number"
                    name="completionTime"
                    className={completionTimeClasses}
                    id="completionTime"
                    onChange={completionTimeChangeHandler}
                    aria-describedby="completionTimeFeedback"
                    defaultValue={completionDays}
                    required
                  />
                  {completionTime.error && (
                    <div id="completionTimeFeedback" className="invalid-feedback">
                      {completionTime.error}
                    </div>
                  )}
                </div>
                <input type="text" defaultValue="editMode" hidden={true} name="mode" />
                <input type="number" defaultValue={courseId} hidden={true} name="courseId" />
                <div className="modal-footer pt-3">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      hideModal();
                    }}
                  >
                    {" "}
                    Close
                  </button>
                  <button type="submit" className="btn btn-default btn-success" disabled={!formIsValid}>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
