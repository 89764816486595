// import { NavBar } from "./navBar";
import { Link as MuiLink } from "@mui/material";

interface RedErrorProps {
  message: string;
}

export const RedError = ({ message }: RedErrorProps) => {
  let returnLink = <a href="/login"> Return to login</a>;

  let title = "An error occurred!";

  return (
    <>
      <div
        id="notfound"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <div className="notfound">
          <div className="notfound-404">
            <h1>500</h1>
          </div>
          <h2>{title}</h2>
          <p>
            {message}
            {returnLink}
          </p>
          <div className="notfound-social">
            <MuiLink>
              <i className="fab fa-facebook"></i>
            </MuiLink>
            <MuiLink>
              <i className="fab fa-instagram"></i>
            </MuiLink>
            <MuiLink>
              <i className="fab fa-twitter"></i>
            </MuiLink>
            <MuiLink>
              <i className="fab fa-snapchat-ghost"></i>
            </MuiLink>
          </div>
        </div>
      </div>
    </>
  );
};
