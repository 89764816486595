import "../../assets/css/status-prompt.css";

interface StatusChangePromptProps {
  status: "approve" | "reject" | "";
  enrolleeName: string;
  enrolleeId: number;
  changeEnrolleeStatusFunc: (enrolleeId: number, action: string) => Promise<void>;
}

export const StatusChangePrompt = ({
  status,
  enrolleeId,
  enrolleeName,
  changeEnrolleeStatusFunc,
}: StatusChangePromptProps) => {
  const bgColor = status === "approve" ? "#1C935C" : "#F83D50";
  const message =
    status === "approve"
      ? `${enrolleeName} will recieve an approval email regarding the application`
      : `${enrolleeName} will recieve an rejection email regarding the application`;
  console.log(enrolleeId, status);

  return (
    <>
      <div className="modal fade" id="confirmModal" tabIndex={-1} aria-labelledby="confirmModal" aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ backgroundColor: "transparent", borderRadius: 10 }}
        >
          <div className="modal-content" style={{ border: "none", borderRadius: "1.5rem", overflow: "hidden" }}>
            <div className="modal-body p-0">
              <div className="react-confirm-alert-body-element">
                <div className="custom-ui" style={{ backgroundColor: bgColor }}>
                  <h1>Are you sure?</h1>
                  <p style={{ fontSize: "1.2rem" }}>{message}</p>
                  <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal">
                    No
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      changeEnrolleeStatusFunc(enrolleeId, status);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
