export const berenCourses = {
  flutter: {
    name: "Flutter & Dart - The Complete Guide",
    totalLectures: 379,
    author: "Maximilian Schwarzmüller",
  },
  reactNative: {
    name: "React Native - The Practical Guide",
    totalLectures: 289,
    author: "Maximilian Schwarzmüller",
  },
  nodeJS: {
    name: "NodeJS - The Complete Guide (MVC, REST APIs, GraphQL, Deno)",
    totalLectures: 543,
    author: "Maximilian Schwarzmüller",
  },
  javascript: {
    name: "The Complete JavaScript Course: From Zero to Expert",
    totalLectures: 320,
    author: "Jonas Schmedtmann",
  },
  react: {
    name: "React - The Complete Guide (incl Hooks, React Router, Redux)",
    totalLectures: 512,
    author: "Maximilian Schwarzmüller",
  },
  sqlAndDatabases: {
    name: "Complete SQL and Databases Bootcamp: Zero to Mastery",
    totalLectures: 282,
    author: "Mo Binni",
  },
  typesript: {
    name: "Understanding TypeScript",
    totalLectures: 198,
    author: "Maximilian Schwarzmüller",
  },
  graphqlWithReact: {
    name: "GraphQL with React: The Complete Developers Guide",
    totalLectures: 116,
    author: "Stephen Grider",
  },
  uiUx: {
    name: "Complete Web & Mobile Designer : UI/UX,Figma",
    totalLectures: 329,
    author: "Daniel Schifano",
  },
};

export const courseObjects = Object.values(berenCourses);
export const courseArr = courseObjects.map(course => course.name);
