import { Helmet } from "react-helmet";

import "../assets/css/contact.css";
import { ContactUsBody } from "../components";

export const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Contact us</title>
      </Helmet>
      {/*--------- Error Messge sent from server should be renderered here ------------*/}

      <ContactUsBody />
    </>
  );
};

export default ContactUs;
