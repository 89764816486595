import { useState, useRef, useEffect, FormEvent, useMemo } from "react";
import Calendar from "react-calendar";
import Dialog from "@mui/material/Dialog";
import { ClockLoader } from "react-spinners";
import DialogContent from "@mui/material/DialogContent";

import "../../assets/css/calendar.css";
import { TileContentFunc } from "react-calendar/dist/cjs/shared/types";
import { formatDate, getDatesInRange, isDateInArray } from "../../util";
import { ActiveStudents, AttendanceStatus, DateRangeStructure, ErrorType, GetAttendanceResp } from "../../models";
import { usePostStudentUpdateMutation, useGetAttendanceMutation } from "../../store/rtk/admin-api";
import { NotificationModal } from "../common/notification-modal";
import { ClockLoadingSpinner } from "../common/loading-spinner";

interface SetExpectedDatesCalenderProps {
  selectedMember: ActiveStudents | null;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
}
const today = new Date();

export const SetExpectedDatesCalender = ({ setShow, show, selectedMember }: SetExpectedDatesCalenderProps) => {
  const [selectedRange, setSelectedRange] = useState<Date[]>([]);
  const [year, setYear] = useState<number>(today.getUTCFullYear());
  const [month, setMonth] = useState<number>(today.getUTCMonth());
  const [overallRanges, setOverallRanges] = useState<DateRangeStructure[]>([]);
  const [datesWithProgress, setDatesWithProgress] = useState<[] | Date[]>([]);
  const [isPostingUpdate, setIsPostingUpdate] = useState(false);
  const [shouldRefetchAttendance, setShouldRefetchAttendance] = useState(false);
  const [message, setMessage] = useState("");
  const attendanceHasBeenFetched = useRef<boolean>(false);
  const [getAttendance, { isLoading }] = useGetAttendanceMutation();
  const [postUpdate] = usePostStudentUpdateMutation();
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [errorFetchingAttendance, setErrorFetchingAttendance] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const updatesHaveBeenPosted = useRef<boolean>(false);
  const startDate = useMemo(() => new Date(selectedMember!.enrollmentDate), [selectedMember]);

  const combinedRange = overallRanges.flatMap(range => range.range);

  useEffect(() => {
    if ((!attendanceHasBeenFetched.current && month >= startDate.getUTCMonth()) || shouldRefetchAttendance) {
      const sendDataToServer = async () => {
        try {
          const data = { enrollmentId: selectedMember!.id, month: month, year: year };
          const serverRes = (await getAttendance(data)) as { data: GetAttendanceResp; error: ErrorType };

          // console.log("Attendance Days : ", serverRes);
          if (serverRes.error) {
            (attendanceHasBeenFetched as { current: boolean }).current = true;
            setServerError(serverRes.error.data.error.message);
            setShowErrorBanner(true);
            setErrorFetchingAttendance(true);
            if (shouldRefetchAttendance) setShouldRefetchAttendance(false);
          }

          // const daysAttended = serverRes.data.map(dates => dates.date);
          const daysAttendedObjArr = serverRes.data.filter(dates => dates.attendanceFlag !== "pending");
          const datesWithProgress = daysAttendedObjArr.map(dateObj => new Date(dateObj.date));
          setDatesWithProgress(datesWithProgress);
          if (shouldRefetchAttendance) setShouldRefetchAttendance(false);

          (attendanceHasBeenFetched as { current: boolean }).current = true;
        } catch (err) {
          (attendanceHasBeenFetched as { current: boolean }).current = true;
          if (shouldRefetchAttendance) setShouldRefetchAttendance(false);
          // console.error("Fetching attendance Error : ", err);
          setServerError(`Unable to fetch attendance`);
          setShowErrorBanner(true);
          setErrorFetchingAttendance(true);
        }
      };

      sendDataToServer();
    }
  }, [getAttendance, selectedMember, month, year, startDate, shouldRefetchAttendance]);

  // Is to be executed on clicking a day tile so as to set up a range of days
  const handleOnclickDay = (date: Date) => {
    if (selectedRange.length === 0) {
      // start of selection
      setSelectedRange([date]);
    } else if (selectedRange.length === 1) {
      // end of selection
      const [start] = selectedRange;
      const end = date;
      const range = getDatesInRange(start, end);
      setSelectedRange(range);
      // To keep track of the selected ranges and persist it in this state
      setOverallRanges(prevState => [...prevState, { start: start, end: end, range: range }]);
    } else if (selectedRange.length > 2) {
      setSelectedRange([date]);
    } else {
      // reset selection
      setSelectedRange([date]);
    }
  };

  const tileContent: TileContentFunc = ({ date, view }) => {
    // Only highlight tiles in monthly view
    if (view === "month") {
      // if date is sunday
      if (
        date.getDay() === 0 &&
        !isDateInArray(datesWithProgress, date) &&
        date > today &&
        !isLoading &&
        !combinedRange.some(rangeDate => rangeDate.toDateString() === date.toDateString())
      ) {
        return (
          <p
            style={{
              display: "block",
              padding: "0.075rem",
              fontSize: "0.66rem",
              color: "#C52227",
            }}
          >
            {AttendanceStatus.UNEXPECTED}
          </p>
        );
        // If date is found in the selected range
      } else if (
        combinedRange.length > 1 &&
        combinedRange.some(rangeDate => rangeDate.toDateString() === date.toDateString())
      ) {
        if (combinedRange.some(rangeDate => rangeDate.toDateString() === date.toDateString())) {
          return (
            <p
              style={{
                display: "block",
                padding: "0.075rem",
                fontSize: "0.66rem",
                color: "#E28D0E",
              }}
            >
              {AttendanceStatus.EXCUSED}
            </p>
          );
        }
        // All other days should be set to have the 'Expected" text
      } else if (isDateInArray(datesWithProgress, date) || date < today) {
        return (
          <p
            style={{
              display: "block",
              padding: "0.5rem",
              fontSize: "0.87rem",
              color: "#000",
              textAlign: "center",
            }}
          >
            ---
          </p>
        );
      } else if (isLoading) {
        return (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "0.5rem",
              fontSize: "0.87rem",
              color: "#36d7b7",
              textAlign: "center",
              width: "100%",
            }}
          >
            <ClockLoader color="#36d7b7" size={25} />
          </p>
        );
      } else {
        return (
          <p
            style={{
              display: "block",
              padding: "0.075rem",
              fontSize: "0.75rem",
              color: "#038A22",
            }}
          >
            {AttendanceStatus.EXPECTED}
          </p>
        );
      }
    }
  };

  function tileDisabled({ date }: { date: Date }) {
    const today = new Date();
    const tileDate = date;

    today.setHours(0, 0, 0, 0);
    tileDate.setHours(0, 0, 0, 0);

    return isDateInArray(datesWithProgress, tileDate) || date < today || errorFetchingAttendance;
  }

  const setTileClassName = ({ date }: { date: Date }) => {
    // Set the time component to midnight for both dates
    date.setHours(0, 0, 0, 0);

    if (
      date.getDay() === 0 &&
      !isDateInArray(datesWithProgress, date) &&
      date > today &&
      !combinedRange.some(rangeDate => rangeDate.toDateString() === date.toDateString())
    ) {
      return "unexpected";
    } else if (combinedRange.some(rangeDate => rangeDate.toDateString() === date.toDateString())) {
      return "excused";
    } else if (isDateInArray(datesWithProgress, date) || date < today) {
      return "unaccounted";
    } else {
      return "expected";
    }
  };

  const undoClickHandler = () => {
    setSelectedRange([]);
    setOverallRanges([]);
  };

  const postUpdateForStudent = async (date: Date) => {
    const timestamp = date.getTime() - date.getTimezoneOffset() * 60 * 1000;
    try {
      const serverRes = (await postUpdate({ timestamp, trackerId: selectedMember!.trackerId as number })) as {
        data: string;
        error: ErrorType;
      };

      if (serverRes.error) {
        (updatesHaveBeenPosted as { current: boolean }).current = true;
        // console.log("ServerRes,error posting update: ", serverRes.error.data.error.message);
        setServerError(serverRes.error.data.error.message);
        setShowErrorBanner(true);
        setIsPostingUpdate(false);
        return;
      }
      // console.log("Server res : ", serverRes.data);
    } catch (err) {
      (updatesHaveBeenPosted as { current: boolean }).current = true;
      // console.error("Error posting update for : ", date);
      setSelectedRange([]);
      setOverallRanges([]);
      setServerError(`Something went wrong while posting the updates`);
      setShowErrorBanner(true);
    }
  };

  const saveClickHandler = (event: FormEvent) => {
    event.preventDefault();
    // fetchExcusedDates(combinedRange);
    // setStartEditing(false);

    setMessage(
      `Please wait as we post updates starting from ${formatDate(combinedRange[0])} to ${formatDate(
        combinedRange[combinedRange.length - 1]
      )}`
    );

    setIsPostingUpdate(true);
    // combinedRange.forEach(date => {
    //   console.log("Date : ", date);
    //   if (!updatesHaveBeenPosted.current) {
    //     postUpdateForStudent(date);
    //   }
    //   return;
    // });

    const postUpds = async () => {
      try {
        for (const date of combinedRange) {
          if (!updatesHaveBeenPosted.current) {
            await postUpdateForStudent(date);
          }
        }
        (updatesHaveBeenPosted as { current: boolean }).current = true;
        setShouldRefetchAttendance(true);
        setIsPostingUpdate(false);
        setSelectedRange([]);
        setOverallRanges([]);
      } catch (error) {
        (updatesHaveBeenPosted as { current: boolean }).current = true;
        setSelectedRange([]);
        console.log("error : ", error);
        setOverallRanges([]);
        setServerError(`Something went wrong while posting the updates`);
        setShowErrorBanner(true);
      }
    };
    postUpds();
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
            setErrorFetchingAttendance(false);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}

      {isPostingUpdate && <ClockLoadingSpinner message={message} title="Setting excused days" />}

      <Dialog open={show} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12">
              <Calendar
                // minDetail="month"
                className={["calendar-tile-mth"]}
                // The hebrew calendartype works as either Hebrew or hebrew in diff computers
                calendarType="hebrew"
                onActiveStartDateChange={({ action, activeStartDate, value, view }) => {
                  // console.log(`Tile has changed ${activeStartDate!.getMonth()} ${activeStartDate!.getFullYear()}`);
                  (attendanceHasBeenFetched as { current: boolean }).current = false;
                  setMonth(activeStartDate!.getMonth());
                  setYear(activeStartDate!.getFullYear());
                }}
                showNeighboringMonth={false}
                defaultValue={new Date()}
                tileContent={tileContent}
                tileDisabled={tileDisabled}
                tileClassName={setTileClassName}
                onClickDay={handleOnclickDay}
              />
            </div>

            <div
              className="col-lg-4 excuse-sidebar"
              style={{
                borderLeft: "2px solid #EFEFEF",
                borderTopLeftRadius: 15,
                borderBottomLeftRadius: 15,
              }}
            >
              <div
                className="mt-4 mb-5 text-center"
                style={{
                  display: "flex",
                  borderTop: "none",
                  justifyContent: "center",
                }}
              >
                {/* <button type="button" className="btn btn-primary">
                  Start Editing
                </button>
                <button type="button" className="btn btn-secondary px-3" onClick={undoClickHandler}>
                  Undo
                </button> */}
                <h4>Select a range of dates on the calendar </h4>
              </div>

              <div className="container excuse-sidebar-container">
                <form action="" className="excuse-form" method="post" onSubmit={saveClickHandler}>
                  <div className="card border-info mb-3 excuse-date-card">
                    <div className="card-header ">Start Day</div>
                    <div className="card-body text-info">
                      <h5 className="card-title">
                        {selectedRange.length > 0 ? formatDate(selectedRange[0]) : "------"}{" "}
                      </h5>
                    </div>
                  </div>
                  <div className="card border-danger mb-3 excuse-date-card">
                    <div className="card-header ">End Day</div>
                    <div className="card-body text-danger">
                      <h5 className="card-title">
                        {selectedRange.length > 1 ? formatDate(selectedRange[selectedRange.length - 1]) : "------"}
                      </h5>
                    </div>
                  </div>
                  {/* <div className="mb-3 excuse-textarea" style={{ maxWidth: "18rem" }}>
                    <label htmlFor="exampleFormControlTextarea1" className="form-label">
                      Reason to be excused
                    </label>
                    <textarea className="form-control " id="exampleFormControlTextarea1" rows={3}></textarea>
                  </div> */}
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <button type="button" className="btn btn-secondary px-3" onClick={undoClickHandler}>
                      Undo
                    </button>

                    <button type="submit" className="btn btn-success" disabled={selectedRange.length < 2}>
                      Save changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
