import { useState, useEffect } from "react";

interface ModalProps {
  show: boolean;
  onClose: () => void;
  message: string;
  success: boolean;
}

export const NotificationModal: React.FC<ModalProps> = ({ show, onClose, message, success }) => {
  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    setShowModal(show);
    const timeout = setTimeout(() => {
      setShowModal(false);
      // To clear state in location object()
      window.history.replaceState({}, document.title);
      onClose();
    }, 6000);

    return () => clearTimeout(timeout);
  }, [show, onClose]);

  const modalClass = `modal fade ${showModal ? "show" : ""}`;
  const modalBackdropClass = `modal-backdrop fade ${showModal ? "show" : ""}`;
  const modalDialogClass = "modal-dialog";
  const modalContentClass = "modal-content";
  const modalHeaderClass = "modal-header";
  const modalBodyClass = "modal-body";
  // const modalFooterClass = "modal-footer";
  const alertClass = `alert alert-${success ? "success" : "danger"}`;

  return (
    <>
      <div className={modalClass} role="dialog" style={{ display: "block", height: "max-content", zIndex: 10000 }}>
        <div className={modalDialogClass}>
          <div className={modalContentClass}>
            <div className={modalHeaderClass}>
              <button type="button" className="btn-close" onClick={onClose}></button>
            </div>
            <div className={modalBodyClass}>
              <div className={alertClass} role="alert">
                {message}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={modalBackdropClass} onClick={onClose}></div>
    </>
  );
};
