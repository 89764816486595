import { useState, ChangeEvent, FormEvent } from "react";

import { NotificationModal } from "../common/notification-modal";
import { useUpdateRoleMutation } from "../../store/rtk/admin-api";
import { LoadingSpinner } from "../common/loading-spinner";
import { ErrorType } from "../../models";

interface PermissionModalProps {
  setShowPermissionModal: React.Dispatch<React.SetStateAction<boolean>>;
  permissionType: "revoke" | "grant" | "";
  setShowSuccessBanner: React.Dispatch<React.SetStateAction<boolean>>;
  setServerResp: React.Dispatch<React.SetStateAction<string | null>>;
}

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const initialState = {
  inputData: "",
  isValid: false,
  isTouched: false,
  error: "",
};

export const PermissionModal = ({
  permissionType,
  setShowPermissionModal,
  setServerResp,
  setShowSuccessBanner,
}: PermissionModalProps) => {
  const [email, setEmailInput] = useState(initialState);

  const [serverError, setServerError] = useState<null | string>(null);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  // const [serverResp, setServerResp] = useState<null | string>(null);
  // const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [updateRole, { isLoading }] = useUpdateRoleMutation();

  const redPrimary = "#f0392c";
  const greenPrimary = "#33A949";

  const emailChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const trimmedInput = e.target.value.trim();
    if (emailRegex.test(trimmedInput)) {
      setEmailInput({
        inputData: trimmedInput,
        isValid: true,
        isTouched: true,
        error: "",
      });
    } else {
      setEmailInput({
        inputData: trimmedInput,
        isValid: false,
        isTouched: true,
        error: "Enter a valid email",
      });
    }
  };

  const submitEventHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const data = {
      userToUpdate: formData.get("email") as string,
      revoke: permissionType === "grant" ? false : true,
    };

    // console.log("Data : ", data);

    const sendRegDataToServer = async () => {
      const serverRes = (await updateRole(data)) as { data: string; error: ErrorType };
      if (serverRes.error) {
        setServerError(serverRes.error.data.error.message);
        setShowErrorBanner(true);
        return;
      }

      setServerResp(serverRes.data);
      setShowSuccessBanner(true);
      hideModal();
    };
    sendRegDataToServer();
  };

  const hideModal = () => {
    setShowPermissionModal(false);
  };

  const emailClasses =
    email.isValid === false && email.isTouched === true
      ? "form-control form-control-user is-invalid"
      : "form-control form-control-user";

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}

      <div
        className="modal fade show"
        style={{ display: "block", zIndex: 8000, zoom: "1.1" }}
        tabIndex={-1}
        role="dialog"
      >
        <div
          className="modal-backdrop show"
          onClick={() => {
            hideModal();
          }}
        ></div>
        {isLoading && <LoadingSpinner />}
        <div className="modal-dialog modal-dialog-centered " style={{ borderColor: "transparent", maxWidth: "35rem" }}>
          <div
            className="modal-body"
            style={{
              zIndex: 2000,
              margin: "auto",
            }}
          >
            <div
              className="modal-content "
              style={{
                // border: permissionType === "grant" ? "2px solid #66ccff" : "",
                overflow: "hidden",
                backgroundColor: "#fff",
              }}
            >
              <div
                className="modal-header"
                style={{
                  justifyContent: "center",
                  borderBottom: permissionType === "grant" ? `1px solid ${greenPrimary}` : `0.5px solid #ec7b73`,
                }}
              >
                <h2
                  className="text-center fs-4 modal-title permissionModalHeader"
                  style={{
                    fontWeight: "600",
                    color: permissionType === "grant" ? `${greenPrimary}` : `${redPrimary}`,
                    textTransform: "uppercase",
                  }}
                >
                  {permissionType === "grant" ? "Grant admin permission" : "Revoke admin permission"}
                </h2>
              </div>
              <div className="container modal-body px-5 permissionFormContainer " style={{ textAlign: "initial" }}>
                <form action="" method="post" onSubmit={submitEventHandler}>
                  <div className="form-wrapper-sign">
                    <label htmlFor="">Email</label>
                    <div className="d-flex flex-row input-group">
                      <input
                        type="email"
                        name="email"
                        className={emailClasses}
                        id="email"
                        onChange={emailChangeHandler}
                        aria-describedby="emailFeedback"
                        value={email.inputData}
                        required
                      />
                      {email.error && (
                        <div id="emailFeedback" className="invalid-feedback">
                          {email.error}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-12 mt-3 text-center d-flex justify-content-center">
                    <button
                      className={`btn btn-${permissionType === "grant" ? "success" : "danger"} px-5 align-self-center`}
                      disabled={!email.isValid}
                      type="submit"
                      style={{
                        width: "max-content",
                      }}
                    >
                      submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
