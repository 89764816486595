import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEditing: false,
  isGivingUpdate: false,
  isGivingTestimonial: false,
};

const updates = createSlice({
  name: "updates",
  initialState: initialState,
  reducers: {
    isGivingUpdate() {
      return { isEditing: false, isGivingUpdate: true, isGivingTestimonial: false };
    },
    isEditingUpdate() {
      return { isEditing: true, isGivingUpdate: false, isGivingTestimonial: false };
    },
    hasGivenUpdates() {
      return { isEditing: false, isGivingUpdate: false, isGivingTestimonial: false };
    },
    showTestimonial() {
      return { isEditing: false, isGivingUpdate: false, isGivingTestimonial: true };
    },
    hideTestimonial() {
      return { isEditing: false, isGivingUpdate: false, isGivingTestimonial: false };
    },
  },
});

export const updatesReducer = updates.reducer;
export const updatesSliceActions = updates.actions;
