import List from "@mui/material/List";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { ListItem, Card, Grid } from "@mui/material";
import { formatDate, shortenString } from "../../../util";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";

const CourseProgressHeader = () => {
  const userData = useSelector((state: RootState) => state.user);

  const enrollmentDate = formatDate(new Date(userData?.enrollmentDate || 1));
  const curCourse = userData.currentTracker?.course.name || "???";

  return (
    <>
      <Card
        elevation={1}
        sx={{
          mb: { xs: 0, sm: 0, md: 0, lg: 1, xl: 1 },
          mt: { xs: 2, sm: 2, md: 2, lg: 0, xl: 0 },
          borderRadius: "1rem 1rem 0  0",
          overflow: "clip",
          border: "1px solid #C8C8C8",
        }}
      >
        <Grid container spacing={2}>
          <Grid sx={{ borderRight: { md: "1px solid #C8C8C8" } }} item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ListItem>
              <ListItemIcon>
                <CastForEducationIcon />
              </ListItemIcon>
              <ListItemText
                sx={{ fontSize: "1.05rem", textTransform: "uppercase", fontWeight: 400 }}
                primary="Course in progress"
              />
            </ListItem>

            <List component="div" disablePadding>
              <ListItem disablePadding sx={{ pl: 2 }}>
                <ListItemIcon></ListItemIcon>
                <ListItemText sx={{ fontSize: "1rem" }} primary={shortenString(curCourse, 30)} />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <ListItem>
              <ListItemIcon>
                <AccessTimeRoundedIcon />
              </ListItemIcon>
              <ListItemText
                sx={{ fontSize: "1.05rem", textTransform: "uppercase", fontWeight: 400 }}
                primary="Course enrollment date"
              />
            </ListItem>
            <List disablePadding component="div">
              <ListItem disablePadding sx={{ pl: 2 }}>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={enrollmentDate} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default CourseProgressHeader;
