const ContactForm = () => {
  return (
    <div
      className="contact-form col-lg-6 mt-4"
      data-aos="fade-left"
      data-aos-delay="300"
    >
      <form id="contact-form" method="POST" action="/contact">
        <div className="form-group py-2">
          <label htmlFor="fullName">Full Name</label>
          <input
            type="text"
            placeholder="Your Name"
            name="fullName"
            className="form-control"
          />
        </div>

        <div className="form-group py-2">
          <label htmlFor="email">Email address</label>
          <input
            type="email"
            placeholder="example@email.com"
            name="email"
            className="form-control"
          />
        </div>

        <div className="form-group py-2">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            placeholder="Subject"
            name="subject"
            className="form-control"
          />
        </div>

        <div className="form-group py-2">
          <label htmlFor="message">Message</label>
          <textarea
            rows={6}
            placeholder="Message"
            name="msg"
            className="form-control"
          ></textarea>
        </div>

        <button type="submit" className="btn btn-success py-2">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
