import { Link } from "react-router-dom";

import classes from "../../assets/css/project.module.css";
import prjScreenshot from "../../assets/images/prjscreenshot.jpg";

export const SectionCard: React.FC<{
  author: String;
  projectTitle: String;
  projectDescription: String;
}> = ({ author, projectTitle, projectDescription }) => {
  return (
    <section className={classes.card}>
      <div className={classes["card__intro"]}>
        <h1>
          Author : <span className={classes["card__intro__author"]}>{author}</span>
        </h1>
        <h2>Title : {projectTitle}</h2>
        <p>{projectDescription}</p>
        <section className={classes["card__intro__links"]}>
          <Link to="#">
            <button className={classes.btn}>Live Demo</button>
          </Link>
          <Link to="#">
            <button className={classes.btn}>
              <i className="fa-brands fa-github"></i>Github
            </button>
          </Link>
        </section>
      </div>
      <div className={classes["card__content"]}>
        <img src={prjScreenshot} alt="" className={classes["card__content__img"]} />
        <ul className={classes["card__content__src__lnks"]}>
          <li>CSS</li>
          <li>JavaScript</li>
          <li>HTML</li>
          <li>PHP</li>
        </ul>
      </div>
    </section>
  );
};
