import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSideBar: true,
};

const sideBarSlice = createSlice({
  name: "sideBar",
  initialState: initialState,
  reducers: {
    changeSideBarState(state) {
      const newState = { showSideBar: !state.showSideBar };
      return newState;
    },
    hideSideBar() {
      return { showSideBar: false };
    },
  },
});

export const sideBarReducer = sideBarSlice.reducer;
export const sideBarSliceActions = sideBarSlice.actions;
