import { useNavigate } from "react-router-dom";

import { SummaryCard } from "./summary-card";

interface AcademySummaryProps {
  memberNo: number;
}

export const AcademySummary = ({ memberNo }: AcademySummaryProps) => {
  const navigate = useNavigate();

  const membersClickHandler = () => {
    navigate("/progress-admin/members");
  };

  return (
    <div className="row">
      <div className="col-lg-6 mb-4">
        <div
          className={`card textwhite bg-primary text-white shadow py-5 px-1 text-center`}
          onClick={membersClickHandler}
          style={{ cursor: "pointer" }}
        >
          <div className="card-body">
            <p className="m-0">Members</p>
            <p className="text-white-50 small m-0">{memberNo}</p>
          </div>
        </div>
      </div>
      <SummaryCard title={"Projects"} data={0} color={"bg-success "} iconName="as" />{" "}
      <SummaryCard title={"Active Tasks"} data={0} color={"bg-info"} iconName="as" />{" "}
      <SummaryCard title={"Upcoming Tasks"} data={0} color={"bg-warning"} iconName="as" />
    </div>
  );
};
