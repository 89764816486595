import React, { useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { useSelector } from "react-redux";

import "../../assets/css/filepond/filepond-plugin-image-preview.min.css";
import "../../assets/css/filepond/filepond.min.css";
import { RootState } from "../../store";
import { DOMAIN } from "../../constants";

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
// import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

// image/png, image/jpeg, image/gif

const initialFileState = {
  imageFilePath: "",
  isTouched: false,
  isUploaded: false,
  isValid: false,
  isReverted: false,
  error: "",
};

interface ImageFileUploaderProps {
  fetchFormState?: (imagePath: string, uploadState: boolean) => void;
}

export const ImageFileUploader = ({ fetchFormState }: ImageFileUploaderProps) => {
  const [imageFile, setImageFile] = useState(initialFileState);
  const imageFileInputClasses =
    imageFile.isValid === false && imageFile.isTouched === true && imageFile.error !== ""
      ? "form-control pt-3 form-control-user is-invalid"
      : "form-control pt-3 form-control-user";

  const accessToken = useSelector((state: RootState) => state.auth.accessToken) || localStorage.getItem("accessToken");

  return (
    <div className={imageFileInputClasses}>
      <FilePond
        allowReorder={true}
        allowMultiple={false}
        credits={false}
        allowFileSizeValidation={true}
        maxFileSize={"5MB"}
        labelMaxFileSizeExceeded={"File is too large"}
        maxFiles={1}
        acceptedFileTypes={["image/png", "image/jpeg"]}
        checkValidity={true}
        name="upload"
        server={{
          url: `${DOMAIN}`,
          process: { url: "/upload", method: "POST", headers: { Authorization: `Bearer ${accessToken}` } },
          revert: {
            url: "/delete",
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
          },
        }}
        onprocessfilerevert={() => {
          if (fetchFormState) {
            fetchFormState("", false);
          }
          setImageFile({
            imageFilePath: "",
            isTouched: true,
            isValid: false,
            isUploaded: false,
            isReverted: true,
            error: "",
          });
        }}
        onprocessfile={(error, file) => {
          // Data sent from the server : file.serverId
          if (!error) {
            const serverResponse: {
              url: string;
              msg: string;
            } = JSON.parse(file.serverId);
            const filePathDigitalOcean = serverResponse.url;
            // console.log("filePathDigitalOcean: ", filePathDigitalOcean);
            // console.log("serverResponse: ", serverResponse);

            //  Send upload state and imageurl to parent component
            if (fetchFormState) {
              fetchFormState(filePathDigitalOcean, true);
            }
            setImageFile({
              imageFilePath: filePathDigitalOcean,
              isTouched: true,
              isValid: true,
              isUploaded: true,
              isReverted: false,
              error: "",
            });
          }
        }}
        labelIdle='<span class="filepond--label-action">Upload Your new image</span>'
      />
      {imageFile.error && (
        <label
          className="row mt-0 pt-0 mb-3 "
          style={{
            display: "block",
            color: "#dc3545",
            fontSize: "0.875em",
            marginTop: "0.1rem",
          }}
        >
          {imageFile.error}
        </label>
      )}
    </div>
  );
};
