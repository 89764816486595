import { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box } from "@mui/material";

import { ErrorType, Role, TokenResp } from "../../models";
import { authSliceActions } from "../../store/auth-slice";
import { useGetTokenMutation } from "../../store/rtk";
import { useLazyGetRoleQuery } from "../../store/rtk/admin-api";
import { getBody, setCodeVerifier } from "../../constants";
import { RedError } from "../../components/common/red-error";
import { RootState } from "../../store";
import { HourGlass } from "../../components";
// import bcrypt from "bcryptjs";
// import { sendLogBody } from "../../util/send-log";

export const AuthCallback = () => {
  const [err, setErr] = useState("");
  const called = useRef(false);
  const [searchParams] = useSearchParams();
  const [isGettingToken, setIsGettingToken] = useState(true);
  const [getToken, { error }] = useGetTokenMutation();
  const [getRole] = useLazyGetRoleQuery();
  const code = searchParams.get("code");
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [sendLogs] = useSendLogsMutation();

  const body = getBody();
  const getTokenData = useMemo(() => {
    return {
      authCode: code!,
      clientId: body.clientId,
      grantType: "authorization_code",
      codeVerifier: body.codeVerifier!,
    };
  }, [body, code]);

  useEffect(() => {
    if (error) {
      setIsGettingToken(false);
      setErr(`${error}`);
    }
  }, [error]);

  useEffect(() => {
    // console.log(" Auth callback body : ", body);
    (async () => {
      if (isLoggedIn === false) {
        try {
          if (called.current) return; // prevent rerender caused by StrictMode
          called.current = true;
          // console.log(" Auth callback code verifier : ", body.codeVerifier);

          // const cCha = localStorage.getItem("codeChallenge");
          // const validChallenge = await bcrypt.compare(body.codeVerifier!, cCha!);
          // await sendLogs(sendLogBody(`code verifier 🌑AFTER🌑 redirection : ${body.codeVerifier}`));
          // await sendLogs(sendLogBody(`code challenge 🌑AFTER🌑 redirection : ${cCha}`));
          // await sendLogs(sendLogBody(`Valid code challenge 🌑AFTER🌑 redirection : ${validChallenge}`));

          const serverRes = (await getToken(getTokenData)) as { data: TokenResp; error: ErrorType };
          // console.log(" == Auth callback code verifier : ", body.codeVerifier);

          if (serverRes.error) {
            localStorage.removeItem("codeVerifier");
            body.codeVerifier = setCodeVerifier()!;
            return;
          }
          dispatch(
            authSliceActions.login({
              accessToken: serverRes.data.accessToken,
              refreshToken: serverRes.data.refreshToken,
              refreshTokenExp: Date.now() + serverRes.data.refreshTokenExpiresIn * 1000,
            })
          );

          localStorage.removeItem("codeVerifier");
          body.codeVerifier = setCodeVerifier()!;
          const role = (await getRole(null)) as { data: { role: Role }[]; error: ErrorType };
          // console.log("Role obj :", role);

          if (role.error) {
            dispatch(authSliceActions.logout());
            localStorage.removeItem("codeVerifier");
            body.codeVerifier = setCodeVerifier()!;
            navigate("/login", { state: { noAccount: role.error } });
          }
          const transFormedRoles = Array.isArray(role.data) ? role.data.map(r => r.role) : [];

          // const token = decodeToken(serverRes.data.accessToken as string);

          setIsGettingToken(false);
          transFormedRoles.forEach(role => {
            if (role === "admin" || role === "superAdmin") {
              navigate("/progress-admin");
              return;
            } else if (role === "student") {
              navigate("/progress");
            }
          });
        } catch (err) {
          dispatch(authSliceActions.logout());
          localStorage.removeItem("codeVerifier");
          body.codeVerifier = setCodeVerifier()!;
          navigate("/login", { state: { err: true } });
        }
      }
    })();
    return () => {
      localStorage.removeItem("codeVerifier");
      body.codeVerifier = setCodeVerifier()!;
    };
  }, [isLoggedIn, navigate, code, getToken, dispatch, getRole, getTokenData, body]);

  return (
    <>
      {err && <RedError message={err} />}
      {(!err || isGettingToken) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
            overflowY: "hidden",
          }}
        >
          <Box mb={2}>
            <HourGlass />
          </Box>
          <h3 style={{ textAlign: "center" }}>Authenticating ...</h3>
        </div>
      )}
    </>
  );
};
