import { useRouteError, useLocation, Link } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";

import "../../assets/css/error.css";
import { NavBar } from "./navBar";

export const ErrorPage = () => {
  const error: any = useRouteError();
  const location = useLocation();

  let returnLink = <Link to="/"> Return to homepage</Link>;

  let title = "An error occurred!";
  let message = "Something went wrong!";

  if (location.state && location.state.error) {
    message = location.state.error;
  }

  if (error.status === 500) {
    message = error.data.message;
  }

  if (error.status === 404) {
    title = "Oops! Nothing was found";
    message = "The page you are looking for might have been removed had its name changed or is temporarily unavailable";
  }

  if (error.status === 201) {
    title = "No content";
    message = error.data.message;
  }

  if (error.status === 401) {
    title = "Forbidden";
    message = error.data.message;
  }

  return (
    <>
      <NavBar />
      <div
        id="notfound"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          overflowY: "hidden",
        }}
      >
        <div className="notfound">
          <div className="notfound-404">
            <h1>{error.status}</h1>
          </div>
          <h2>{title}</h2>
          <p>
            {message}
            {returnLink}
          </p>
          <div className="notfound-social">
            <MuiLink>
              <i className="fab fa-facebook"></i>
            </MuiLink>
            <MuiLink>
              <i className="fab fa-instagram"></i>
            </MuiLink>
            <MuiLink>
              <i className="fab fa-twitter"></i>
            </MuiLink>
            <MuiLink>
              <i className="fab fa-snapchat-ghost"></i>
            </MuiLink>
          </div>
        </div>
      </div>
    </>
  );
};
