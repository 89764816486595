const ContactDetailSection = () => {
  return (
    <div className="contact-info col-lg-6" data-aos="fade-right" data-aos-delay="300">
      <h3>Contact Details</h3>
      <p>
        TechRise Academy is a technology boot camp located at Moi University, Kesses. We equip learners comprehensively
        on frontend, backend, and mobile development. We also offer training in devops, cyber security, ethical hacking,
        and networking. We use the latest in-demand tech stack in all our offerings.
      </p>
      <div className="contact-details">
        <div className="py-2">
          <i className="bi bi-geo-alt-fill text-success"></i>
          <span> Moi University, Kesses</span>
        </div>

        <div className="py-2">
          <i className="bi bi-telephone-fill text-success "></i>
          <span>Phone: +254-725-761-794</span>
        </div>

        <div className="py-2">
          <i className="bi bi-envelope-fill text-success"></i>
          <span>Email: info@kberen.com</span>
        </div>
      </div>
    </div>
  );
};

export default ContactDetailSection;
