import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { parResp, ParBody, TokenData, TokenResp } from "../../models";
import { OAUTH_DOMAIN } from "../../constants";
import { transformErrorResponse } from "./error-handler";

export const authApi = createApi({
  reducerPath: "authorize",
  baseQuery: fetchBaseQuery({
    baseUrl: `${OAUTH_DOMAIN}`,
    prepareHeaders(headers) {
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: build => ({
    par: build.mutation<parResp, ParBody>({
      query: body => ({ url: `/par`, method: "POST", body: JSON.stringify(body) }),
      transformErrorResponse: transformErrorResponse,
    }),
    getToken: build.mutation<TokenResp, TokenData>({
      query: body => ({ url: `/get-token`, method: "POST", body: JSON.stringify(body) }),
      transformErrorResponse: transformErrorResponse,
    }),
  }),
});

export const { useParMutation, useGetTokenMutation } = authApi;
