import { Link as MuiLink } from "@mui/material";

import { email, locationAddress, orgDescription, phoneNo, services } from "../../constants";
import "../../assets/css/index.css";
import "../../assets/css/about.css";
import "../../assets/css/footer.css";

export const Footer = () => {
  const emailHref = `mailto:${email}`;
  const phoneHref = `tel:${phoneNo}`;

  return (
    <footer
      className="footer-dark footer"
      //  data-aos="fade-up"
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-3 item">
            <h3>Services</h3>
            <ul>
              {services.map(service => (
                <li key={service}>
                  <MuiLink>{service}</MuiLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-sm-6 col-md-3 item">
            <h3>Address</h3>
            <ul>
              <li style={{ opacity: 0.9 }}>{locationAddress}</li>
              <li>
                <span style={{ opacity: 0.9 }}> Contact us : </span>
                <a href={phoneHref}>{phoneNo}</a>
              </li>
              <li>
                <span style={{ opacity: 0.9 }}> Write us : </span>
                <a href={emailHref}>{email}</a>
              </li>
            </ul>
          </div>
          <div className="col-md-6 item text">
            <h3>TechRise Academy</h3>
            <p>{orgDescription}</p>
          </div>
          <div className="col item social">
            <MuiLink>
              <i className="fab fa-facebook"></i>
            </MuiLink>
            <MuiLink>
              <i className="fab fa-instagram"></i>
            </MuiLink>
            <MuiLink>
              <i className="fab fa-twitter"></i>
            </MuiLink>
            <MuiLink>
              <i className="fab fa-snapchat-ghost"></i>
            </MuiLink>
          </div>
        </div>
        <p className="copyright">&copy; {`${new Date().getUTCFullYear()}`} BEREN, LLC</p>
      </div>
    </footer>
  );
};
