import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";

import { DOMAIN } from "../../constants";
import { EnrollmentData } from "../../models";
import { transformErrorResponse } from "./error-handler";

export const enrollmentApi = createApi({
  reducerPath: "enrollmentApi",
  tagTypes: ["Enrollment"],
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN,
    prepareHeaders(headers) {
      headers.set("Content-Type", "application/json");
      return headers;
    },
    timeout: 10000,
  }),

  endpoints: builder => ({
    enrollPublicUser: builder.mutation<string, EnrollmentData>({
      query: data => {
        return {
          url: `/enrol`,
          method: "POST",
          body: JSON.stringify(data),
        };
      },
      transformErrorResponse: transformErrorResponse,
    }),
  }),
});

export const { useEnrollPublicUserMutation } = enrollmentApi;
