import { useEffect } from "react";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import DonutChart from "./sub-components/donut-chart";
import { chartSliceActions } from "../../store/chart-slice";
import { RootState } from "../../store";

const ProgressChart = () => {
  const dispatch = useDispatch();

  const chart = useSelector((state: RootState) => state.chart);
  const userData = useSelector((state: RootState) => state.user);

  // Set chart data to reflect progress of users current course as a default value
  useEffect(() => {
    if (userData.currentTracker) {
      const completedPercentage = userData.currentTracker.progress;
      const lecNo = userData.currentTracker.course.totalLecNo;
      const progress = Math.round((completedPercentage / 100) * lecNo);

      const defaultUserChartData = {
        selectedCourse: {
          name: userData.currentTracker.course.name,
          progress: `${progress} / ${lecNo}`,
        },
        chartData: [
          { value: userData.currentTracker.progress, name: "completed" },
          { value: 100 - userData.currentTracker.progress, name: "Incomplete" },
        ],
      };
      dispatch(chartSliceActions.setChartData(defaultUserChartData));
    }
  }, [dispatch, userData]);

  return (
    <>
      <Card
        elevation={1}
        sx={{
          height: { xs: "max-content", sm: "max-content", md: "284px", lg: "284px", xl: "284px" },
          border: "1px solid #c8c8c8",
        }}
      >
        <CardHeader
          sx={{ p: 0 }}
          title={
            <Box sx={{ display: "flex", justifyContent: "space-around", pt: { xs: 2, sm: 2, lg: 0, xl: 0 } }}>
              <Typography sx={{ fontSize: "1.05rem", textTransform: "uppercase", textAlign: "center" }}>
                Progress: {chart.chartData && chart.chartData[0].value}%
              </Typography>
              <Typography>{chart.selectedCourse.progress}</Typography>
            </Box>
          }
        ></CardHeader>
        <Box id="trafficChart">
          <DonutChart data={chart.chartData} />
        </Box>
      </Card>
    </>
  );
};

export default ProgressChart;
