const localIP = "http://192.168.3.83:3000";

//============================== uncomment during local development ========================
// export const DOMAIN = "http://192.168.3.83:3000";
// export const OAUTH_DOMAIN = localStorage.getItem("oauth") as string;
// ==========================================================================

// ============================== uncomment during production ============================
export const DOMAIN = process.env.REACT_APP_ENV === "lcl" ? localIP : window.location.origin;
// export const DOMAIN = window.location.origin;
export const OAUTH_DOMAIN = localStorage.getItem("oauth");
// ==========================================================================

//TODO: change the logic to fetch init data to happen immediately the page is loaded and not during login
export const CLIENT_ID = localStorage.getItem("clientId");
