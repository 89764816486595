import { useEffect } from "react";
import { Provider } from "react-redux";
import AOS from "aos";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Home, AboutUs, ContactUs, PersonalDashboard, AdminDashboard, Projects, RootLayoutAdmin } from "./pages";
import { AdminProfile, AdminMembersTab, AdminEnrolleesTab, AdminInquiryTab, AdminCoursesTab } from "./pages";
import { Login, AuthCallback, AdminMembersHistory, AdminMembersUserHistory, AdminMembersCourseHistory } from "./pages";
import { PersonalCourseHistory, PersonalUserHistory, personalDashboardLoader, adminDashboardLoader } from "./pages";
import { ErrorPage } from "./components";
import { courseAction } from "./pages";
import { RootLayout } from "./pages/rootLayout";
import "aos/dist/aos.css";
import { store } from "./store";
// import store from "./store";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      { path: "/about", element: <AboutUs /> },
      { path: "/contact", element: <ContactUs /> },
      {
        path: "/progress",
        errorElement: <ErrorPage />,
        children: [
          { index: true, element: <PersonalDashboard />, loader: personalDashboardLoader },
          { path: "/progress/history", element: <PersonalUserHistory /> },
          { path: "/progress/history/:user", element: <PersonalCourseHistory /> },
        ],
      },
      { path: "/project", element: <Projects /> },
      { path: "/login", element: <Login /> },
      { path: "/auth/callback", element: <AuthCallback /> },
    ],
  },
  {
    path: "/progress-admin",
    element: <RootLayoutAdmin />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <AdminDashboard />, loader: adminDashboardLoader },
      { path: "/progress-admin/profile", element: <AdminProfile /> },
      { path: "/progress-admin/members", element: <AdminMembersTab /> },
      {
        path: "/progress-admin/history",
        children: [
          { index: true, element: <AdminMembersHistory /> },
          { path: "/progress-admin/history/:user", element: <AdminMembersUserHistory /> },
          { path: "/progress-admin/history/:user/:course", element: <AdminMembersCourseHistory /> },
        ],
      },
      { path: "/progress-admin/enrollees", element: <AdminEnrolleesTab /> },
      { path: "/progress-admin/inquiries", element: <AdminInquiryTab /> },
      {
        path: "/progress-admin/courses",
        element: <AdminCoursesTab />,
        action: courseAction,
      },
    ],
  },
]);
