import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";

import "../../assets/css/bootstrap.min.css";
import { RootState } from "../../store";

export const AdminSideBar = () => {
  const showSideBar = useSelector((state: RootState) => state.sideBar.showSideBar);
  const { pathname } = useLocation();

  const isUserHistory = pathname.startsWith("/progress-admin/history/");

  return (
    <>
      <nav
        style={{ marginTop: 10, position: "fixed", top: 0, bottom: 0, zIndex: 120, display: showSideBar ? "" : "none" }}
        className="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0"
      >
        <div className="d-flex flex-column p-0" style={{ height: "100%" }}>
          {/* <Link className="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0" to="#" /> */}
          <hr className="sidebar-divider my-0" />
          <ul
            className="navbar-nav text-light  sideBarUl"
            style={{ height: "100%", display: "flex", justifyContent: "center" }}
            id="accordionSidebar"
          >
            <li className="nav-item">
              <NavLink className="nav-link" end to="/progress-admin">
                <i className="fas fa-tachometer-alt"></i>
                <span className="nav-text">Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" end to="/progress-admin/profile">
                <i className="fas fa-user"></i>
                <span className="nav-text">Profile</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" end to="/progress-admin/members">
                <i className="fas fa-users"></i>
                <span className="nav-text">Members</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                style={() => (isUserHistory ? { fontWeight: 700, color: "#fff" } : {})}
                end
                to="/progress-admin/history"
              >
                <i>
                  <WorkHistoryIcon sx={{ fontSize: "1rem", py: 0, my: 0, color: isUserHistory ? "#fff" : "" }} />
                </i>
                <span className="nav-text">Member History</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" end to="/progress-admin/enrollees">
                <i className="fas fa-clipboard-list"></i>
                <span className="nav-text">Enrollees</span>
              </NavLink>
            </li>
            <li className="nav-item">
              {/* <NavLink className="nav-link" end to="/progress-admin/inquiries">
              <i className="fas fa-envelope"></i>
              <span className="nav-text">Inquiries</span>
            </NavLink> */}
              <NavLink className="nav-link" end to="/progress-admin/courses">
                <i className="fas fa-tasks "></i>
                <span className="nav-text">Courses</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};
