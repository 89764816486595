import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import { Button, Chip } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ReactPaginate from "react-paginate";
import PersonPinRoundedIcon from "@mui/icons-material/PersonPinRounded";

import { NotificationModal } from "../common/notification-modal";
import { useGetAdminsQuery, useUpdateRoleMutation } from "../../store/rtk/admin-api";
import { ErrorType, FetchedAdmins } from "../../models";
import { LoadingSpinnerWithoutBackDrop } from "../common/loading-spinner";
import { ConfirmationPrompt } from "../common/confirmation-modal";
import { decodeToken } from "../../util";

interface ViewAdminsModalProps {
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean;
}

export const ViewAdminsModal = ({ setShow, show }: ViewAdminsModalProps) => {
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const [serverResp, setServerResp] = useState<null | string>(null);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [admins, setAdmins] = useState<[] | FetchedAdmins[]>([]);
  const [confirmationPromptMessg, setconfirmationPromptMessg] = useState("");
  const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);
  const { data, isLoading, error } = useGetAdminsQuery(null);
  const [selectedAdmin, setSelectedAdmin] = useState<null | FetchedAdmins>(null);
  const [updateRole, { isLoading: isRevokingRole }] = useUpdateRoleMutation();
  const itemsPerPage = 5;
  const token = localStorage.getItem("accessToken");

  const decodedToken = decodeToken(token as string);

  function AdminList({ admins }: { admins: FetchedAdmins[] }) {
    return (
      <>
        {admins &&
          Array.isArray(admins) &&
          admins.map(admin => {
            const labelId = `checkbox-list-secondary-label-${admin.email}`;
            return (
              <ListItem
                sx={{ py: 1 }}
                key={admin.email}
                secondaryAction={
                  admin.email === decodedToken?.email ? (
                    <Chip
                      label="CURRENT ACCOUNT"
                      variant="outlined"
                      sx={{
                        borderColor: "#4262BE",
                        borderRadius: "4px",
                        padding: "5px 3px",
                        fontSize: "14px",
                        color: "#4262BE",
                      }}
                    />
                  ) : (
                    <Button
                      color="error"
                      disabled={admin.email === decodedToken?.email || admin.role === "superAdmin"}
                      variant="outlined"
                      onClick={() => {
                        setSelectedAdmin(admin);
                        setconfirmationPromptMessg(`${admin.email} will be revoked of their admin rights`);
                        setShowConfirmationPrompt(true);
                      }}
                    >
                      Revoke role
                    </Button>
                  )
                }
                disablePadding
              >
                <ListItemButton disableRipple>
                  <ListItemAvatar>
                    {admin.email === decodedToken?.email ? (
                      <Avatar sx={{ backgroundColor: "#4262BE" }}>
                        <PersonPinRoundedIcon />{" "}
                      </Avatar>
                    ) : (
                      <Avatar alt={admin.email} />
                    )}
                  </ListItemAvatar>
                  <ListItemText id={labelId} primary={admin.email} />
                </ListItemButton>
              </ListItem>
            );
          })}
      </>
    );
  }

  useEffect(() => {
    if (data && Array.isArray(data)) {
      setAdmins(data);
      // console.log("Admins : ", data);
    }

    if (error) {
      setShowErrorBanner(true);
      setServerError("Unable to fetch admins");
    }
  }, [data, error]);

  const endOffset = itemOffset + itemsPerPage;
  const currentAdmins = admins.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(admins.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % admins.length;
    setItemOffset(newOffset);
  };

  const handleClose = () => {
    if (setShow) setShow(false);
  };

  const revokeRole = async () => {
    const data = {
      userToUpdate: selectedAdmin!.email,
      revoke: true,
    };
    const serverRes = (await updateRole(data)) as { data: string; error: ErrorType };
    if (serverRes.error) {
      setServerError(serverRes.error.data.error.message);
      setShowErrorBanner(true);
      return;
    }

    setServerResp(serverRes.data);
    setShowSuccessBanner(true);
  };

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}

      {serverResp && showSuccessBanner && (
        <NotificationModal
          onClose={() => {
            setShowSuccessBanner(false);
            setServerResp(null);
          }}
          message={serverResp as string}
          show={true}
          success={true}
        />
      )}

      <Dialog open={show} onClose={handleClose} fullWidth maxWidth="sm">
        <div
          className="modal-header"
          style={{
            justifyContent: "center",
            borderBottom: "1px solid #4262BE",
          }}
        >
          <h2
            className="text-center fs-4 modal-title permissionModalHeader"
            style={{
              fontWeight: "600",
              textTransform: "uppercase",
              color: "#4262BE",
            }}
          >
            View all admins
          </h2>
        </div>
        <DialogContent sx={{ justifyContent: "center", display: "flex", py: 1, minHeight: "25rem" }}>
          <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
            {isLoading && (
              <div style={{ textAlign: "center" }}>
                <LoadingSpinnerWithoutBackDrop />
              </div>
            )}

            {Array.isArray(data) && <AdminList admins={currentAdmins} />}
          </List>

          {admins.length > 5 && (
            <div className="row">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <ReactPaginate
                    nextLabel="next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="< previous"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </ul>
              </nav>
            </div>
          )}
        </DialogContent>
      </Dialog>
      {showConfirmationPrompt && (
        <ConfirmationPrompt
          status="danger"
          message={confirmationPromptMessg}
          setShowConfirmationPrompt={setShowConfirmationPrompt}
          confirmationAction={revokeRole}
          isLoading={isRevokingRole}
        />
      )}
    </>
  );
};
