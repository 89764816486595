import { useSelector } from "react-redux";
import { ErrorType } from "../../../models";
import { useState, FormEvent, ChangeEvent, useEffect } from "react";
import { TextField, Box, Grid, Button } from "@mui/material";
import { usePostUpdateMutation } from "../../../store/rtk";
import { LoadingSpinner } from "../../common/loading-spinner";
import { NotificationModal } from "../../common/notification-modal";
import { RootState } from "../../../store";

interface UpdatesFormProps {
  hideModal: () => void;
}

const initialState: {
  inputData: string;
  isValid: boolean;
  isTouched: boolean;
  error: null | string;
} = {
  inputData: "",
  isValid: false,
  isTouched: false,
  error: null,
};

export const UpdatesForm = ({ hideModal }: UpdatesFormProps) => {
  const [lectureNo, setLectureNo] = useState(initialState);
  const [lectureComment, setLectureComment] = useState(initialState);
  const [errorMesg, setErrorMesg] = useState<null | string>(null);
  const [showErrorBanner, setShowErrorBanner] = useState(true);
  const [postUpdate, { isLoading, error }] = usePostUpdateMutation();
  const userData = useSelector((state: RootState) => state.user);
  const currentCourseLecNo = userData.currentTracker?.course.totalLecNo;

  useEffect(() => {
    if (error) {
      console.log("Error in useEffect : ", error);
      setErrorMesg(`${error}`);
      setShowErrorBanner(true);
    }
  }, [error]);

  const lectureNoChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!currentCourseLecNo) {
      setLectureNo({
        inputData: "",
        isValid: false,
        isTouched: false,
        error: "You currently haven't been  assigned a course, please contact the admins to assign you one",
      });
      return;
    }
    const trimmedInput = e.target.value.trim();
    if (trimmedInput.length === 0) {
      setLectureNo({
        inputData: trimmedInput,
        isValid: false,
        isTouched: true,
        error: "Enter a valid lecture number",
      });
    } else if (+trimmedInput > +currentCourseLecNo) {
      setLectureNo({
        inputData: trimmedInput,
        isValid: false,
        isTouched: true,
        error: `Max lecture number allowed is ${currentCourseLecNo}`,
      });
    } else if (+trimmedInput < 1) {
      setLectureNo({
        inputData: trimmedInput,
        isValid: false,
        isTouched: true,
        error: `Invalid lecture number`,
      });
    } else {
      setLectureNo({
        inputData: trimmedInput,
        isValid: true,
        isTouched: true,
        error: null,
      });
    }
  };
  const lectureCommentChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setLectureComment({
        inputData: e.target.value,
        isValid: false,
        isTouched: true,
        error: "Enter a valid for lecture title",
      });
    } else {
      setLectureComment({
        inputData: e.target.value,
        isValid: true,
        isTouched: true,
        error: null,
      });
    }
  };

  const formIsValid = lectureComment.isValid && lectureNo.isValid;

  const submitEventHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    if (!userData.currentTracker?.id) {
      setErrorMesg("You currently haven't been assigned a course");
      setShowErrorBanner(true);
      return;
    }
    const data = {
      trackerId: userData.currentTracker.id,
      curLecNo: Number(formData.get("lecNo")),
      comment: formData.get("comment") as string,
    };

    const sendDataToServer = async () => {
      try {
        const serverRes = (await postUpdate(data)) as { data: string; error: ErrorType };
        if (serverRes.error) {
          console.log("serverRes.error : ", serverRes.error);
          return;
        }
        hideModal();
      } catch (err) {
        // console.error("Post update Err : ", err);
        setErrorMesg(`An error occurred`);
        setShowErrorBanner(true);
      }
    };
    sendDataToServer();
  };

  const disableSubmit = userData.todayUpdate ? false : !formIsValid;

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {errorMesg && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setErrorMesg(null);
          }}
          message={errorMesg}
          show={true}
          success={false}
        />
      )}

      <form method="POST" onSubmit={submitEventHandler}>
        <Box
          sx={{
            "& .MuiTextField-root": { m: 1 },
            justifyContent: "center",
          }}
          component="div"
        >
          <Grid container sx={{ justifyContent: "center", px: 0 }} component="div">
            <TextField
              onChange={lectureNoChangeHandler}
              fullWidth
              defaultValue={userData && userData.todayUpdate?.curLecNo}
              error={!lectureNo.isValid && lectureNo.isTouched}
              name="lecNo"
              helperText={<div>{lectureNo.error}</div>}
              type="number"
              label="Lecture No."
              id="outlined-size-small"
            />
            <TextField
              onChange={lectureCommentChangeHandler}
              type="text"
              name="comment"
              defaultValue={userData && userData.todayUpdate?.comment}
              error={!lectureComment.isValid && lectureComment.isTouched}
              fullWidth
              helperText={<div>{lectureComment.error}</div>}
              label="Course Title"
              id="outlined-size-normal"
            />
          </Grid>
          <Grid container sx={{ justifyContent: "center", mt: 2 }} component="div">
            <Button type="submit" variant="contained" color="success" size="large" disabled={disableSubmit}>
              submit
            </Button>
          </Grid>
        </Box>
      </form>
    </>
  );
};
