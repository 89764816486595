import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Avatar } from "@mui/material";

import { useGetStudentsQuery } from "../../store/rtk/admin-api";
import { Student } from "../../models";
import { NotificationModal } from "../../components";
import { LoadingSpinnerForTable } from "../../components/common/loading-spinner";

export const AdminMembersHistory = () => {
  const [students, setStudents] = useState<[] | Student[]>([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);

  const { data, isLoading, error } = useGetStudentsQuery(null);
  const navigate = useNavigate();
  const itemsPerPage = 5;

  useEffect(() => {
    if (error) {
      setServerError(`Unable to fetch  members`);
      setShowErrorBanner(true);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      setStudents(data);
      // console.log("Students : ", data);
    }
  }, [data]);

  function StudentRows({ students }: { students: Student[] }) {
    return (
      <>
        {students &&
          students.map((student, index) => (
            <tr
              key={student.id}
              style={{ cursor: "pointer" }}
              onClick={() => {
                localStorage.setItem("selectedStd", student.fName);
                navigate(`/progress-admin/history/${student.curTrackerId}`);
              }}
            >
              <td>
                <Avatar alt={student.fName} src={student.imageUrl} sx={{ width: "3rem", height: "3rem" }} />
              </td>

              {/* <td className="py-1">{students.findIndex(course => course === student) + 1}</td> */}

              <td>
                {student.fName} {student.lName}
              </td>
              <td>{student.email}</td>
              <td>{student.status !== "approved" ? student.status : "ongoing"}</td>
            </tr>
          ))}
      </>
    );
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentStudents = students.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(students.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % students.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-lg-11 grid-margin stretch-card">
            <div className="card">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <h4> Member History</h4>
                </div>
              </div>
            </div>
            {/* Content goes Here */}
            <div className="card-body shadow">
              <div className="table-responsive" style={{ minHeight: "15rem" }}>
                <table className="table  table-hover">
                  <thead>
                    <tr>
                      <th>Member</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody style={{ color: "#505156" }}>
                    {isLoading && <LoadingSpinnerForTable />}
                    <StudentRows students={currentStudents} />
                    {!isLoading && students.length === 0 && (
                      <tr
                        style={{
                          backgroundColor: "#fff",
                          height: "100%",
                          border: "none",
                          borderColor: "transparent",
                          fontSize: "1.2rem",
                        }}
                      >
                        <td colSpan={100} style={{ textAlign: "center", backgroundColor: "#fff" }}>
                          No members found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="row">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <ReactPaginate
                      nextLabel="next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel="< previous"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
