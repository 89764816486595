import { useSelector } from "react-redux";
import { UserHistory } from "../../components";
import { RootState } from "../../store";

export const PersonalUserHistory = () => {
  const userData = useSelector((state: RootState) => state.user);

  return (
    <>
      <UserHistory isAdmin={false} enrollmentId={userData.currentTracker?.id} />
    </>
  );
};
