import { useState } from "react";
import ReactPaginate from "react-paginate";
import axios from "axios";

import { EnrolleeData } from "../../models";
import { LoadingSpinnerForTable } from "../common/loading-spinner";

interface AdminApprovedEnrolleesTableProps {
  approvedEnrollees: EnrolleeData[];
  modalDataUseState: [string, React.Dispatch<React.SetStateAction<string>>];
  isLoading: boolean;
}

export const AdminApprovedEnrolleesTable = ({
  approvedEnrollees,
  modalDataUseState,
  isLoading,
}: AdminApprovedEnrolleesTableProps) => {
  const [itemOffset, setItemOffset] = useState(0);
  const setModalData = modalDataUseState[1];
  const itemsPerPage = 5;

  const fetchFile = async (resumePath: string) => {
    try {
      const response = await axios.get(resumePath, { responseType: "stream" });
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  function EnrolleeRow({ approvEnrollees }: { approvEnrollees: EnrolleeData[] }) {
    return (
      <>
        {approvEnrollees &&
          approvEnrollees!.map((enrollee, index) => (
            <tr key={enrollee.id}>
              <td className="py-1">{approvedEnrollees.findIndex(user => user === enrollee) + 1}</td>
              <td>{`${enrollee.fName} ${enrollee.lName}`}</td>
              <td>{enrollee.email}</td>
              <td>{enrollee.phoneNo}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#resumeModal"
                  onClick={() => {
                    setModalData(enrollee.resumeUrl);
                    // fetchFile("https://elm-resources.fra1.digitaloceanspaces.com/upload-1680783732190.pdf");
                    fetchFile(enrollee.resumeUrl);
                  }}
                >
                  View
                </button>
              </td>
              {/* <td>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm m-0 px-1"
                  data-bs-toggle="modal"
                  data-bs-target="#assignCourseModal"
                  onClick={() => {
                    // setModalData(enrollee.resumeUrl);
                    setSelectedEnrollee(enrollee);
                  }}
                >
                  Assign Course
                </button>
              </td> */}
            </tr>
          ))}
      </>
    );
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentEnrollees = approvedEnrollees.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(approvedEnrollees.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % approvedEnrollees.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="container-fluid " style={{ marginTop: 25, paddingLeft: 10, paddingRight: 10 }}>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card shadow" style={{ border: "1px solid #198754" }}>
            <div className="card-header" style={{ backgroundColor: "#5CB58B" }}>
              <h4>Approved Enrollees</h4>
            </div>
            <div className="card-body">
              <div className="table-responsive" style={{ minHeight: "15rem" }}>
                <table className="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>email</th>
                      <th>Phone No.</th>
                      <th>Resume</th>
                    </tr>
                  </thead>
                  <tbody>
                    <EnrolleeRow approvEnrollees={currentEnrollees} />
                    {isLoading && <LoadingSpinnerForTable />}
                    {!isLoading && approvedEnrollees.length === 0 && (
                      <tr
                        style={{
                          backgroundColor: "transparent",
                          height: "100%",
                          border: "none",
                          borderColor: "transparent",
                          fontSize: "1.2rem",
                        }}
                      >
                        <td colSpan={100} style={{ textAlign: "center", backgroundColor: "transparent" }}>
                          No members found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="row">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <ReactPaginate
                      nextLabel="next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel="< previous"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  </ul>
                </nav>
              </div>

              {/* <AssignCourseModal selectedEnrollee={selectedEnrollee} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
