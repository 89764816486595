import { createSlice } from "@reduxjs/toolkit";
import { UserDetails, Update, Course } from "../models";

type Tracker = { id: number; progress: number; course: { totalLecNo: number; name: string } };

export interface EnrolleeInfo {
  email?: string;
  fName?: string;
  imageUrl?: string;
  lName?: string;
  phoneNo?: string;
}

export type USER_STATE = {
  enrolleeData: EnrolleeInfo;
  enrollmentDate?: string;
  currentTracker?: Tracker;
  completedTrackers: Tracker[];
  remainingCourses: Course[];
  todayUpdate?: Update;
  testimonial?: string;
  enrollmentId?: number;
};

const initialState: UserDetails = {
  enrolleeData: { email: "", fName: "", imageUrl: "", lName: "", phoneNo: "" },
  completedTrackers: [],
  remainingCourses: [],
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUserDetails(state, action: { type: string; payload: UserDetails }) {
      const { email, fName, lName, phoneNo, imageUrl } = action.payload.enrolleeData;
      const enrolleeData = {
        email,
        fName,
        imageUrl,
        lName,
        phoneNo,
      };

      return {
        enrolleeData: enrolleeData,
        enrollmentDate: action.payload.enrollmentDate,
        completedTrackers: action.payload.completedTrackers,
        remainingCourses: action.payload.remainingCourses,
        todayUpdate: action.payload.todayUpdate,
        currentTracker: action.payload.currentTracker,
        testimonial: action.payload.testimonial,
        enrollmentId: action.payload.enrollmentId,
      };
    },
  },
});

export const userReducer = userSlice.reducer;
export const userSliceActions = userSlice.actions;
