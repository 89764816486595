/* eslint-disable jsx-a11y/anchor-is-valid */
import { Helmet } from 'react-helmet';

import { SectionCard } from '../components';
import classes from '../assets/css/project.module.css';
import homeScreen from '../assets/images/homescreen.png';
import prjScreenshot from '../assets/images/prjscreenshot.jpg';

export const Projects = () => {
  return (
    <>
      <Helmet>
        <title>Projects</title>
      </Helmet>
      <section
        id="card-container"
        className={classes['card-container']}
        style={{ padding: '6px 0 0 0' }}
      >
        <section
          className={classes.card}
          data-aos="fade-in"
          data-aos-delay="200"
        >
          <div className={classes.card__intro}>
            <h1 data-aos="fade-right" data-aos-delay="300">
              Author :{' '}
              <span
                className={`${classes['card__intro__author']} ${classes['text-warning']}`}
              >
                Reuben Khaemba
              </span>
            </h1>
            <h2 className="pt-2">
              Title : <span className="text-warning">Safirisha App</span>{' '}
            </h2>
            <p
              className={`${classes['card-text']} 'pt-2`}
              data-aos="fade-right"
              data-aos-delay="400"
            >
              Safirisha is a transport logistics application that connects
              clients who are in need of truck services, for different purposes
              e.g ferrying construction materials such as ballast, sand,
              building stones to their construction site, lagguages(parcels)
              from one point to another, by connecting them with truck drivers
              nearby. All this at the comfort of your home, all you need is the
              safirisha app installed in your phone, choose the suitable truck
              of your choice, select place of origin and destination, and search
              for nearby trucks that are available and the driver will be
              notified via push notification, he'll accept the request and tend
              to your needs accordingly!
            </p>
          </div>
          <div
            className={classes['card__content']}
            data-aos="fade-left"
            data-aos-delay="400"
          >
            <img src={homeScreen} alt="" className={classes['card-img']} />
          </div>
        </section>
        <section className={classes.card}>
          <div
            className={classes['card__intro']}
            data-aos="fade-left"
            data-aos-delay="200"
          >
            <h1>
              Author :{' '}
              <span className={classes['card__intro__author']}>Jonas</span>
            </h1>
            <h2>Title : Sample Project</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,
              illo? Voluptatem dolore incidunt illum fugiat neque alias iste
              quidem quae soluta. Sequi dignissimos doloribus hic repellendus
              odio dolores dicta officia temporibus mollitia sed inventore
              labore natus eius, explicabo expedita nostrum vitae, dolor, quod
              ut ipsam maiores incidunt asperiores! Consequuntur, placeat.
            </p>
            <section className={classes['card__intro__links']}>
              <a href="#">
                <button className={classes.btn}>Live Demo</button>
              </a>
              <a href="#">
                <button className={classes.btn}>
                  <i className="fa-brands fa-github"></i>Github
                </button>
              </a>
            </section>
          </div>
          <div
            className={classes['card__content']}
            data-aos="fade-right"
            data-aos-delay="200"
          >
            <img
              src={prjScreenshot}
              alt=""
              className={classes['card__content__img']}
            />
            <ul className={classes['card__content__src__lnks']}>
              <li>CSS</li>
              <li>JavaScript</li>
              <li>HTML</li>
              <li>PHP</li>
            </ul>
          </div>
        </section>
        <SectionCard
          author={'Jonas'}
          projectTitle={'Sample Project'}
          projectDescription={
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,illo? Voluptatem dolore incidunt illum fugiat neque alias iste quidem quae soluta. Sequi dignissimos doloribus hic repellendus odio'
          }
        />
        <SectionCard
          author={'Jonas'}
          projectTitle={'Sample Project'}
          projectDescription={
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores,illo? Voluptatem dolore incidunt illum fugiat neque alias iste quidem quae soluta. Sequi dignissimos doloribus hic repellendus odio'
          }
        />
      </section>
    </>
  );
};
