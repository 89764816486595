import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { LogData } from "@beren/shared.log-types";

import { CLIENT_ID, DOMAIN } from "../../constants";
import { transformErrorResponse } from "./error-handler";
import * as Models from "../../models";
import { RootState } from "..";

export const adminApi = createApi({
  reducerPath: "admin",
  tagTypes: ["Admin"],
  baseQuery: fetchBaseQuery({
    baseUrl: DOMAIN,
    timeout: 10000,
    prepareHeaders(headers, { getState }) {
      if (!CLIENT_ID) throw new Error("Missing client ID");

      headers.set("Content-Type", "application/json");
      const token = (getState() as RootState).auth.accessToken || localStorage.getItem("accessToken");
      if (token) headers.set("Authorization", `Bearer ${token}`);

      headers.set("Client-Auth", CLIENT_ID);
      return headers;
    },
  }),
  endpoints: build => ({
    getEnrollees: build.query<Models.EnrolleeData[], null>({
      query: body => ({ url: "/admin/enrollees", method: "GET" }),
      providesTags: ["Admin"],
      transformErrorResponse,
    }),
    getRole: build.query<{ role: Models.Role }[], null>({
      query: body => ({ url: "/get-role", method: "GET" }),
      providesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    getStudents: build.query<Models.Student[], null>({
      query: body => ({ url: "/admin/students", method: "GET" }),
      // providesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    changeEnrolleeStatus: build.mutation<string, { enrollmentId: number; status: string }>({
      query: body => ({ url: "/admin/process-enrollment", method: "POST", body: JSON.stringify(body) }),
      invalidatesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    getCourses: build.query<Models.FetchedCourse[], null>({
      query: body => ({ url: "/acd/get-courses", method: "GET" }),
      providesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    getAdmins: build.query<Models.FetchedAdmins[], null>({
      query: body => ({ url: "/admin/admins", method: "GET" }),
      providesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    getActiveStudents: build.query<Models.ActiveStudents[], null>({
      query: body => ({ url: "/admin/active-students", method: "GET" }),
      providesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    getSpecificActiveStudents: build.query<Models.ActiveStudents, { id: number }>({
      query: id => ({ url: `/admin/active-students?id=${id}`, method: "GET" }),
      providesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    updateRole: build.mutation<string, { userToUpdate: string; revoke?: boolean }>({
      query: body => {
        return { url: `/admin/update-role`, method: "POST", body: JSON.stringify(body) };
      },
      invalidatesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    editCourse: build.mutation<string, Models.EditCourseData>({
      query: body => ({ url: "/admin/upsert-course", method: "POST", body: JSON.stringify(body) }),
      invalidatesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    getAttendance: build.mutation<Models.GetAttendanceResp, Models.GetAttendanceData>({
      query: body => ({ url: "/admin/attendance", method: "POST", body: JSON.stringify(body) }),
      transformErrorResponse: transformErrorResponse,
    }),
    getPendingCourses: build.mutation<Models.RemCourse[], { enrollmentId: number }>({
      query: body => ({ url: "/admin/pending-courses", method: "POST", body: JSON.stringify(body) }),
      transformErrorResponse: transformErrorResponse,
      // invalidatesTags: ["Admin"],
    }),
    getAttendanceScore: build.mutation<number, Models.AttendanceScoreData>({
      query: body => ({ url: "/admin/attendance-score", method: "POST", body: JSON.stringify(body) }),
      transformErrorResponse: transformErrorResponse,
      // invalidatesTags: ["Admin"],
    }),
    postStudentUpdate: build.mutation<string, { timestamp: number; trackerId: number }>({
      query: body => ({ url: "/admin/post-update", method: "POST", body: JSON.stringify(body) }),
      invalidatesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    addAlumni: build.mutation<string, { enrollmentId: number }>({
      query: body => ({ url: "/admin/add-alumni", method: "POST", body: JSON.stringify(body) }),
      // invalidatesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    updatePhoto: build.mutation<string, { imageUrl: string }>({
      query: body => ({ url: "/student/update-image", method: "POST", body: JSON.stringify(body) }),
      // invalidatesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    createCourse: build.mutation<string, Models.CreateCourseData>({
      query: body => ({ url: "/admin/upsert-course", method: "POST", body: JSON.stringify(body) }),
      invalidatesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    deleteCourse: build.mutation<string, { courseId: number }>({
      query: body => ({ url: "/delete-course", method: "DELETE", body: JSON.stringify(body) }),
      invalidatesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    deleteEnrollment: build.mutation<string, { enrollmentId: number }>({
      query: body => ({ url: "/admin/delete-enrollment", method: "DELETE", body: JSON.stringify(body) }),
      invalidatesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    createTracker: build.mutation<string, { enrollmentId: number; courseId: number }>({
      query: body => ({ url: "/admin/create-tracker", method: "POST", body: JSON.stringify(body) }),
      invalidatesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    deleteTracker: build.mutation<string, { trackerId: number }>({
      query: body => ({ url: "/admin/delete-tracker", method: "DELETE", body: JSON.stringify(body) }),
      invalidatesTags: ["Admin"],
      transformErrorResponse: transformErrorResponse,
    }),
    sendLogs: build.mutation<string, LogData>({
      query: data => ({ url: "/log", method: "POST", body: JSON.stringify(data) }),
      transformErrorResponse: transformErrorResponse,
    }),
  }),
});

export const { useGetCoursesQuery, useGetAdminsQuery, useCreateCourseMutation, useGetEnrolleesQuery } = adminApi;
export const { useDeleteCourseMutation, useEditCourseMutation, useChangeEnrolleeStatusMutation } = adminApi;
export const { useGetRoleQuery, useLazyGetRoleQuery, useCreateTrackerMutation, useSendLogsMutation } = adminApi;
export const { useGetStudentsQuery, useGetAttendanceMutation, useDeleteTrackerMutation } = adminApi;
export const { useGetActiveStudentsQuery, useUpdatePhotoMutation, useGetAttendanceScoreMutation } = adminApi;
export const { useAddAlumniMutation, usePostStudentUpdateMutation, useGetPendingCoursesMutation } = adminApi;
export const { useGetSpecificActiveStudentsQuery, useUpdateRoleMutation, useDeleteEnrollmentMutation } = adminApi;
