import heroImage from "../../assets/images/about/hero-img.png";

interface ShowcaseSectionProps {
  setShowEnrollmentModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ShowcaseSection = ({ setShowEnrollmentModal }: ShowcaseSectionProps) => {
  return (
    <section id="showcase-section" className="bg-dark text-light p-5 p-lg-5 pt-lg-5 mb-5 text-center text-sm-start ">
      <div className="container">
        <div className="d-sm-flex align-items-center justify-content-between">
          <div data-aos="fade-up" data-aos-delay="200">
            {/* <% if(isAuthenticated){ %> 
        <h4 className="my-2 ">Welcome onboard <span><%= user.userName %></span>  </h4>
        <% } %>  */}
            <h1>
              Become a <span className="text-warning"> Technology Professional </span>
            </h1>
            <p className="lead my-4">
              Ignite your tech journey by enrolling in our esteemed coding bootcamp – a gateway to a thriving career in
              the world of technology. Our curriculum is meticulously crafted to impart essential skills in the latest
              programming languages and tools, ensuring that you're well-prepared for the dynamic tech landscape. Join
              our community of learners and mentors who are dedicated to your success, and embark on a transformational
              learning experience that paves the way for your future in development.
            </p>
            <button
              className="btn btn-success btn-lg"
              onClick={() => {
                setShowEnrollmentModal(true);
              }}
              style={{ color: "whitesmoke" }}
            >
              Enroll Now
            </button>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
            <img src={heroImage} id="hero-image" className="img-fluid animated " alt="hero_image" />
          </div>
        </div>
      </div>
    </section>
  );
};
