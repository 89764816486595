export const UpdateLectureModal = () => {
  return (
    <div
      className="modal fade"
      id="updateDataModal"
      tabIndex={-1}
      aria-labelledby="updateDataModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="updateDataModalLabel">
              Update lecture
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form action="/progress/?_csrf=4QF9SUbf-kPvmhdKc4RyFv0TgEbvh2a3ATCA" method="POST">
              <div className="mb-3">
                <label htmlFor="lecNo" className="col-form-label">
                  Lecture Number:
                </label>
                <input type="number" className="form-control" id="lecNo" name="lecNo" />
                <input type="hidden" name="curCourse" value="" />
                <input type="hidden" name="userId" value="" />
              </div>

              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                  Close
                </button>
                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
