import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import { RootState } from "../../store";
import { berenCourses } from "../../constants";
import { getUpdatedChartData } from "../../util";
import { chartSliceActions } from "../../store/chart-slice";
import { authSliceActions } from "../../store/auth-slice";

const allCourseObj = Object.values(berenCourses);
const courses = allCourseObj.map(course => course.name);

export const CoursesDropdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state: RootState) => state.user);

  if (userData) {
    dispatch(authSliceActions.logout());
    navigate("/login", { state: { err: true } });
    return;
  }

  const handleSelectChange = (event: React.SyntheticEvent, value: string | null) => {
    const updatedChartData = getUpdatedChartData(`${value}`, userData);
    dispatch(chartSliceActions.setChartData(updatedChartData));
  };

  return (
    <Autocomplete
      disablePortal
      onChange={handleSelectChange}
      id="combo-box-demo"
      options={courses}
      sx={{ width: 300 }}
      renderInput={params => <TextField {...params} label="Select a course" />}
    />
  );
};
