import React from "react";

import { StoredTestimonial } from "../../../models";

interface TestimonialModalProps {
  onClose: () => void;
  selectedTestimonial: StoredTestimonial;
}

export const TestimonialModal = ({ onClose, selectedTestimonial }: TestimonialModalProps) => {
  return (
    <>
      <div className="modal fade show" style={{ display: "block", zIndex: 10000 }} tabIndex={-1}>
        <div
          className="modal-backdrop show"
          style={{ zIndex: "auto" }}
          onClick={() => {
            onClose();
          }}
        ></div>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              {" "}
              <h5 className="modal-title">
                {selectedTestimonial.fName.charAt(0).toUpperCase() + selectedTestimonial.fName.slice(1)}
                's Remarks
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  onClose();
                }}
                aria-label="Close"
              ></button>
            </div>

            <div id="testimonialModalContent" className="modal-body">
              {selectedTestimonial.comment}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
