import * as React from "react";
import { useNavigate } from "react-router-dom";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import SpeedIcon from "@mui/icons-material/Speed";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor = theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

function handleClick(event: React.MouseEvent<Element, MouseEvent>) {
  event.preventDefault();
}

interface UserHistoryBreadcrumbsProps {
  isAdmin: boolean;
  student?: string;
  course?: string;
  studentName?: string;
  userParam?: number;
}

export const UserHistoryBreadcrumbs = ({ course, isAdmin, student, userParam }: UserHistoryBreadcrumbsProps) => {
  const navigate = useNavigate();

  let navItems = (
    <>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/progress");
        }}
      >
        <StyledBreadcrumb label="Dashboard" icon={<SpeedIcon fontSize="small" />} />
      </div>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate(`/progress/history`);
        }}
      >
        <StyledBreadcrumb label="history" icon={<WorkHistoryIcon fontSize="small" />} />
      </div>
      {course && (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/progress/history/${userParam}`);
          }}
        >
          <StyledBreadcrumb label={course} icon={<CastForEducationIcon fontSize="small" />} />
        </div>
      )}
    </>
  );
  if (isAdmin) {
    navItems = (
      <>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            localStorage.removeItem("selectedStd");
            navigate("/progress-admin/history");
          }}
        >
          <StyledBreadcrumb label="Members" icon={<PeopleIcon fontSize="small" />} />
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/progress-admin/history/${student}`);
          }}
        >
          <StyledBreadcrumb label={localStorage.getItem("selectedStd")} icon={<PersonIcon fontSize="small" />} />
        </div>
        {course && (
          <div style={{ cursor: "pointer" }}>
            <StyledBreadcrumb label={course} icon={<CastForEducationIcon fontSize="small" />} />
          </div>
        )}
      </>
    );
  }

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <div style={{ display: "flex", flexDirection: "row" }}>{navItems}</div>
      </Breadcrumbs>
    </div>
  );
};
