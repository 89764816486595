import { createSlice } from "@reduxjs/toolkit";

import { ChartState } from "../models";

// const currentUserCourseLectureNumber = store.getState().user.details
//   .currentLectureNumber as number;
// const currentUserCourse = store.getState().user.details.curCourse;
// const totalLectureNumOfCurrentCourse = getTotalLectureNumberOfUserCurrentCourse(
//   currentUserCourse
// ) as number;

// // value for completed chart section
// const completeValue =
//   (currentUserCourseLectureNumber / totalLectureNumOfCurrentCourse) * 100;

// // value for Incomplete chart section
// const IncompleteValue = 100 - completeValue;

const initialState: ChartState = {
  chartData: [
    {
      value: 0,
      name: "completed",
    },
    {
      value: 100,
      name: "Incomplete",
    },
  ],
  selectedCourse: {
    name: "",
    progress: "",
  },
};

const chartslice = createSlice({
  name: "chart",
  initialState: initialState,
  reducers: {
    setChartData(state, action) {
      return action.payload;
    },
  },
});

export const chartReducer = chartslice.reducer;
export const chartSliceActions = chartslice.actions;
