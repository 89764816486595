import { useParams } from "react-router-dom";

import { CourseHistory } from "../../components";

export const AdminMembersCourseHistory = () => {
  const params = useParams();

  return (
    <>
      <CourseHistory isAdmin={true} student={params.user as string} />
    </>
  );
};
