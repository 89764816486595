import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Avatar } from "@mui/material";

import { ReactComponent as PersonSvg } from "../../assets/svg/person-4.svg";
import { ActiveStudents } from "../../models";
import { LoadingSpinnerForTable } from "../common/loading-spinner";

interface AdminUsersProgressTableProps {
  fetchedMembers: ActiveStudents[];
  isLoading: boolean;
}

export const AdminUsersProgressTable = ({ fetchedMembers, isLoading }: AdminUsersProgressTableProps) => {
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

  function MembersRows({ members }: { members: ActiveStudents[] }) {
    return (
      <>
        {members &&
          members!.map((member, index) => (
            <tr key={member.id}>
              <td className="py-1">
                {member?.imageUrl ? (
                  <Avatar alt={member.fName} src={member.imageUrl} sx={{ width: "3rem", height: "3rem" }} />
                ) : (
                  <PersonSvg
                    style={{
                      fill: "#858796",
                      width: "3rem",
                      borderWidth: "2px",
                      border: "1px solid ",
                      borderRadius: "50%",
                      paddingRight: 0,
                    }}
                  />
                )}
              </td>
              <td className="py-1">
                {member.fName} {member.lName}
              </td>

              <td className="py-1">{member.curCourse ? member.curCourse : "???"}</td>
              <td className="py-1">
                <div className="progress">
                  <div
                    className="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: member.progress ? `${member.progress}%` : "0%" }}
                    aria-valuenow={member.progress ? +member.progress : 0}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {member.progress ? `${member.progress}%` : "0%"}
                </div>
              </td>
            </tr>
          ))}
      </>
    );
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentMembers = fetchedMembers.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(fetchedMembers.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % fetchedMembers.length;
    setItemOffset(newOffset);
  };

  return (
    <div className="card">
      <div className="card-body shadow">
        <div className="table-responsive" style={{ minHeight: "20rem" }}>
          <table className="table  table-hover">
            <thead>
              <tr>
                <th>User</th>
                <th>Name</th>
                <th>Current Course</th>
                <th>Progress</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <LoadingSpinnerForTable />}
              <MembersRows members={currentMembers} />
              {!isLoading && fetchedMembers.length === 0 && (
                <tr
                  style={{
                    backgroundColor: "#fff",
                    height: "100%",
                    border: "none",
                    borderColor: "transparent",
                    fontSize: "1.2rem",
                  }}
                >
                  <td colSpan={100} style={{ textAlign: "center", backgroundColor: "#fff" }}>
                    No members found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="row">
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end">
              <ReactPaginate
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="< previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};
