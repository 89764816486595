import { LoadingSpinner } from "./loading-spinner";

interface ConfirmationPromptProps {
  status: "success" | "danger" | "primary";
  message: string;
  confirmationAction: () => void;
  setShowConfirmationPrompt: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: boolean;
}

export const ConfirmationPrompt = ({
  status,
  message,
  confirmationAction,
  setShowConfirmationPrompt,
  isLoading,
}: ConfirmationPromptProps) => {
  let bgColor = "";
  if (status === "success") {
    bgColor = "#1C935C";
  } else if (status === "danger") {
    bgColor = "#F83D50";
  } else if (status === "primary") {
    bgColor = "#4E73DF";
  }

  const hideModal = () => {
    setShowConfirmationPrompt(false);
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <div
        className="modal fade show"
        // id="confirmationPrompt"
        // aria-labelledby="confirmationPrompt"
        style={{ display: "block", zIndex: 10000 }}
        tabIndex={-1}
        aria-hidden="true"
      >
        <div
          className="modal-backdrop show"
          style={{ zIndex: "auto" }}
          onClick={() => {
            hideModal();
          }}
        ></div>
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ backgroundColor: "transparent", borderRadius: 10 }}
        >
          <div className="modal-content" style={{ border: "none", borderRadius: "1.5rem", overflow: "hidden" }}>
            <div className="modal-body p-0">
              <div className="react-confirm-alert-body-element">
                <div className="custom-ui rmXPadding" style={{ backgroundColor: bgColor }}>
                  <h1>Are you sure?</h1>
                  <p className="custom-ui-messg" style={{ fontSize: "1.2rem" }}>
                    {message}
                  </p>
                  <button
                    type="button"
                    className="btn btn-outline-danger"
                    onClick={() => {
                      hideModal();
                    }}
                  >
                    No
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      confirmationAction();
                      setShowConfirmationPrompt(false);
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
