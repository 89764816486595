interface SummaryCardProps {
  color: string;
  title: string;
  data: number;
  iconName: string;
}

export const SummaryCard = ({
  color,
  title,
  data,
  iconName,
}: SummaryCardProps) => {
  return (
    <div className="col-lg-6 mb-4" style={{ cursor: 'pointer' }}>
      <div
        className={`card textwhite ${color} text-white shadow py-5 px-1 text-center`}
      >
        <div className="card-body">
          <p className="m-0">{title}</p>
          <p className="text-white-50 small m-0">{data}</p>
        </div>
      </div>
    </div>
  );
};
