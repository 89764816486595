import { RootState } from "../../store";
import { Button, Box } from "@mui/material";
import { useState, useEffect } from "react";
import ProgressChart from "./progress-chart";
import { useNavigate } from "react-router-dom";
import { ShouldGiveUpdatesState } from "../../models";
import { useSelector, useDispatch } from "react-redux";
import { TestimonialModal } from "./testimonial-modal";
import CourseOptions from "./sub-components/course-options";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import { updatesSliceActions } from "../../store/updates-slice";
import { CourseAccordion } from "./sub-components/course-accordion";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import CourseProgressHeader from "./sub-components/course-progress-header";

interface CourseProgressSectionProps {
  shouldGiveUpdatesState: ShouldGiveUpdatesState;
  editingState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export const CourseProgressSection = ({ shouldGiveUpdatesState, editingState }: CourseProgressSectionProps) => {
  // const [showTestimonial, setShowTestimonial] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.user);
  const showTestimonial = useSelector((state: RootState) => state.updates.isGivingTestimonial);
  // const [searchParams] = useSearchParams();
  // const testimonialParam = searchParams.get("testimonial");
  // testimonial=true

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // useEffect(() => {
  //   if (testimonialParam && testimonialParam === "true") {
  //     setShowTestimonial(true);
  //   }
  // }, [testimonialParam]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  const closeTestimonial = () => {
    // setShowTestimonial(false);
    dispatch(updatesSliceActions.hideTestimonial());
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12  mb-2  CourseProgressHeader" style={{ margin: 0 }}>
          {windowWidth < 480 && (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "56px" }}>
              <CastForEducationIcon sx={{ mr: 2, fontSize: "1.5rem" }} />
              <h2 style={{ textTransform: "uppercase", textAlign: "center", fontWeight: "bolder" }}>Courses</h2>
            </div>
          )}
          <CourseProgressHeader />
        </div>

        <div className="col-md-6 testimonialBtn">
          <Button
            sx={{ fontWeight: 500, height: "56px" }}
            fullWidth
            variant="contained"
            size="large"
            disabled={!userData.testimonial}
            onClick={() => {
              // setShowTestimonial(true);
              dispatch(updatesSliceActions.showTestimonial());
            }}
          >
            Give testimonial
          </Button>
        </div>
        <div className="col-md-6 courseOptions">
          <CourseOptions />
        </div>

        <div className="col-md-6 courseAccordion">
          <CourseAccordion />
        </div>

        {/* ------------------ progress --------------- */}
        <div className="col-md-6  progressChart">
          <ProgressChart />
          <Box mt={{ xs: 7, sm: 7, md: 2, lg: 2, xl: 2 }} mb={{ xs: 1, sm: 1, md: 0, lg: 0, xl: 0 }}>
            {windowWidth < 480 && (
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <WorkHistoryIcon sx={{ mr: 2, fontSize: "1.5rem" }} />
                <h2 style={{ textTransform: "uppercase", textAlign: "center", fontWeight: "bolder" }}>History</h2>
              </div>
            )}
            <Button
              sx={{
                borderRadius: {
                  xs: "4px",
                  sm: "4px",
                  md: "4px 4px 1rem 4px",
                  lg: "4px 4px 1rem 4px",
                  xl: "4px 4px 1rem 4px",
                },
                fontWeight: 500,
              }}
              fullWidth
              className="viewCourseUpdateHistoryBtn"
              variant="contained"
              size="large"
              disabled={!userData.currentTracker}
              onClick={() => {
                navigate(`/progress/history`);
              }}
            >
              View course update history
            </Button>
          </Box>
        </div>
      </div>

      {/* <CourseUpdates /> */}
      {showTestimonial && <TestimonialModal hideModal={closeTestimonial} />}
    </>
  );
};
