import { createSlice } from "@reduxjs/toolkit";

import { FetchedCourse, EnrolleeData } from "../models";

interface AdminSliceState {
  enrollees: [] | EnrolleeData[];
  courses: [] | FetchedCourse[];
}

const initialState: AdminSliceState = {
  enrollees: [],
  courses: [],
};

const adminSlice = createSlice({
  name: "admin-slice",
  initialState: initialState,
  reducers: {
    setCourses(state, action: { payload: FetchedCourse[] }) {
      return { ...state, courses: action.payload };
    },
    setEnrollees(state, action: { payload: EnrolleeData[] }) {
      return { ...state, enrollees: action.payload };
    },
  },
});

export const adminReducer = adminSlice.reducer;
export const adminSliceActions = adminSlice.actions;
