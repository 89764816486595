import { ClockLoader, ScaleLoader } from "react-spinners";
import React from "react";

export const LoadingSpinner = () => {
  return (
    <>
      <div className="modal fade show" style={{ display: "block", zIndex: 1000050 }} tabIndex={-1} role="dialog">
        <div className="modal-backdrop show"></div>
        <div className="modal-dialog modal-dialog-centered" role="document" onClick={e => e.stopPropagation()}>
          <div
            className="modal-content"
            style={{
              zIndex: 2000,
              backgroundColor: "transparent",
              border: "none",
            }}
          >
            <div
              className="modal-body"
              style={{
                zIndex: 2000,
                backgroundColor: "transparent",
                textAlign: "center",
              }}
            >
              <ScaleLoader color="#36d7b7" height={80} width={15} margin={5} radius={10} />
              {/* <PulseLoader size={50} color="#36d7b7" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface ClockLoadingSpinnerProps {
  message: string;
  title: string;
}

export const ClockLoadingSpinner = ({ message, title }: ClockLoadingSpinnerProps) => {
  return (
    <>
      <div className="modal fade show" style={{ display: "block", zIndex: 1000050 }} tabIndex={-1} role="dialog">
        <div className="modal-backdrop show"></div>
        <div className="modal-dialog modal-dialog-centered" role="document" onClick={e => e.stopPropagation()}>
          <div
            className="modal-content"
            style={{
              zIndex: 2000,
              border: "none",
            }}
          >
            <div
              className="modal-header"
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                color: "#36d7b7",
                textTransform: "uppercase",
              }}
            >
              <h3>{title}</h3>
            </div>
            <div
              className="modal-body"
              style={{
                zIndex: 2000,
                textAlign: "center",
              }}
            >
              <div className="mt-2" style={{ display: "flex", justifyContent: "center" }}>
                <ClockLoader color="#36d7b7" size={80} />
              </div>
              <div className="mt-4">
                <h4>{message}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const LoadingSpinnerWithoutBackDrop = () => {
  return (
    <>
      <div style={{ width: "100%", height: "100%" }}>
        <div>
          <ScaleLoader color="#36d7b7" height={80} width={15} margin={5} radius={10} />
        </div>
      </div>
    </>
  );
};

export const LoadingSpinnerForTable = () => {
  return (
    <>
      <tr style={{ backgroundColor: "#fff", height: "100%", border: "none", borderColor: "transparent" }}>
        <td colSpan={100} style={{ textAlign: "center", backgroundColor: "#fff" }}>
          <LoadingSpinnerWithoutBackDrop />
        </td>
      </tr>
    </>
  );
};
