import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { Avatar, Button } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

import { NavBar, AdminSideBar } from "../../components";
import { sideBarSliceActions } from "../../store/side-bar-slice";
import { LoadingSpinner } from "../../components/common/loading-spinner";
import "../../assets/css/nav.css";
import { RootState } from "../../store";

export const RootLayoutAdmin = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const showSideBar = useSelector((state: RootState) => state.sideBar.showSideBar);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 991) {
      dispatch(sideBarSliceActions.hideSideBar());
    }
  }, [dispatch, windowWidth]);

  const togglerClasses = `adminSideBarTogglerCont ${showSideBar ? "" : "togglerWithoutSideBar"}`;
  const outletClasses = showSideBar ? "outletWithSideBar mt-5" : "outletWithoutSideBar mt-5";

  const animationTiming = {
    enter: 500,
    exit: 900,
  };

  return (
    <>
      {navigation.state === "loading" && <LoadingSpinner />}
      <div className="d-flex flex-column">
        <NavBar />
        <div className="d-flex flex-row">
          <CSSTransition
            in={showSideBar}
            mountOnEnter
            unmountOnExit
            timeout={animationTiming}
            classNames={{ enter: "slideInRight", exitActive: "slideInLeft" }}
          >
            <AdminSideBar />
          </CSSTransition>

          <CSSTransition
            in={showSideBar}
            mountOnEnter={true}
            unmountOnExit={false}
            timeout={animationTiming}
            classNames={{ enter: "slideInRightToggler", exitActive: "slideInLeftToggler" }}
          >
            <div className={togglerClasses}>
              <Avatar sx={{ overflow: "clip", opacity: 0.6 }}>
                <Button
                  onClick={() => {
                    dispatch(sideBarSliceActions.changeSideBarState());
                  }}
                >
                  {showSideBar ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
                </Button>
              </Avatar>
            </div>
          </CSSTransition>
          {/* <div className="flex-grow-1 admin-right-side-content"> */}
          <div
            className={outletClasses}
            onClick={() => {
              if (showSideBar && windowWidth < 991) {
                dispatch(sideBarSliceActions.changeSideBarState());
              }
            }}
          >
            {/* {navigation.state === "loading" ? <AdminSpinner /> : <Outlet />} */}
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
