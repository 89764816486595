import { PdfReader } from "../common/pdf-reader";

interface ResumeModalProps {
  resumePath: string;
}

export const ResumeModal = ({ resumePath }: ResumeModalProps) => {
  return (
    <div className="modal" id="resumeModal" tabIndex={-1}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{ maxWidth: "80%" }}>
        <div className="modal-content">
          <div className="modal-body" style={{ padding: 0 }}>
            <PdfReader resumePath={resumePath} />
          </div>
        </div>
      </div>
    </div>
  );
};
