import { StoredTestimonial } from "../../../models";
import { shortenString } from "../../../util";

interface TestimonialCardProps {
  testimonial: StoredTestimonial;
  setShowTestimonialModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTestimonial: React.Dispatch<React.SetStateAction<StoredTestimonial | null>>;
}

export const TestimonialCard = ({
  testimonial,
  setShowTestimonialModal,
  setSelectedTestimonial,
}: TestimonialCardProps) => {
  const { fName, lName, comment, imageUrl } = testimonial;
  const fNme = fName.charAt(0).toUpperCase() + fName.slice(1);
  const lNme = lName.charAt(0).toUpperCase() + lName.slice(1);

  return (
    <>
      <div className="" data-aos="zoom-in">
        <div className="card bg-light">
          <div className="card-body text-center">
            <img src={imageUrl} className="rounded-circle mb-3 testimonials" alt="" />
            <h3 className="card-title mb-3">{`${fNme} ${lNme}`}</h3>
            <p id="testimonialContent" className="card-text">
              {shortenString(comment, 222)}
            </p>
            <button
              type="button"
              className="btn btn-success text-center"
              onClick={() => {
                setSelectedTestimonial(testimonial);
                setShowTestimonialModal(true);
              }}
            >
              Read more
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
