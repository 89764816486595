import { Avatar } from "@mui/material";
import { green } from "@mui/material/colors";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";

import { shortenString } from "../../util";
import { FetchedCourse } from "../../models";
import "../../assets/css/nav.css";

interface CourseListItemProps {
  index: number;
  currentCourse: FetchedCourse;
  selectedCourse: FetchedCourse | null;
  onPress: (course: FetchedCourse) => void;
}

const ChosenIndicator = () => {
  return (
    <Avatar sx={{ bgcolor: green[500] }}>
      <CheckCircleOutlineOutlinedIcon />
    </Avatar>
  );
};

export const CourseListItem = ({ currentCourse, index, selectedCourse, onPress }: CourseListItemProps) => {
  return (
    <>
      <button
        className="container shadow pb-3 CourseListItem"
        disabled={selectedCourse !== null && selectedCourse !== currentCourse}
        onClick={() => {
          onPress(currentCourse);
        }}
      >
        <div
          className="row mb-1"
          style={{ overflow: "hidden", borderTopLeftRadius: "0.8rem", borderTopRightRadius: "0.8rem" }}
        >
          <div className="col-md-4" style={{ backgroundColor: "#5a9ae3", height: "1rem" }}></div>
          <div className="col" style={{ backgroundColor: "#cadff6", height: "1rem" }}></div>
        </div>
        <div className="d-flex align-items-center pt-2">
          <div
            className="flex-shrink-0 text-center p-3 mr-2"
            style={{
              backgroundColor: "#2068bc",
              color: "white",
              height: "4rem",
              width: "4rem",
              borderRadius: "50%",
            }}
          >
            <h3>{index}</h3>
          </div>
          <div className="flex-grow-1 ms-3">
            <span style={{ display: "block", fontSize: "1.5rem", color: "#2068bc" }}>
              {shortenString(currentCourse.name, 30)}
            </span>
            <span style={{ display: "block", fontSize: "1rem" }}>{currentCourse.author}</span>
          </div>
          {currentCourse === selectedCourse && <ChosenIndicator />}
        </div>
      </button>
    </>
  );
};
