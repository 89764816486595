import ContactDetailSection from './sub-components/contact-detail-section';
import ContactUsHeader from './sub-components/contact-us-header';
import ContactForm from './sub-components/contact-form';

export const ContactUsBody = () => {
  return (
    <section id="contact-us" className="contact-us section-bg">
      <div className="container" data-aos="zoom-in" data-aos-delay="150">
        <div className="row my-5">
          <ContactUsHeader />

          {/* --------- Contact Details ------------ */}
          <ContactDetailSection />

          {/* -------------- Contact Form ----------------- */}
          <ContactForm />
        </div>
      </div>
    </section>
  );
};
