export function isSameMonth(date1: Date, date2: Date): boolean {
  return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

export function isDateInArray(dates: Date[], targetDate: Date): boolean {
  return dates.some(date => date.setHours(0, 0, 0, 0) === targetDate.setHours(0, 0, 0, 0));
}

export function getEarliestDate(dates: Date[]): Date | undefined {
  if (dates.length === 0) {
    return undefined;
  }

  let earliestDate = dates[0];
  for (const date of dates) {
    if (date < earliestDate) {
      earliestDate = date;
    }
  }

  return earliestDate;
}

export function formatDate(date: Date): string {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

// Function to get array of dates based on the start and end dates
export const getDatesInRange = (start: Date, end: Date): Date[] => {
  const range = [];
  const date = new Date(start);
  while (date <= end) {
    range.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return range;
};

export function getFirstDateOfMonth(date: Date) {
  // create a new date object with the same year and month as the argument
  let firstDate = new Date(date.getFullYear(), date.getMonth());
  // return the first date as a string in the format yyyy-mm-dd
  return firstDate.toISOString().slice(0, 10);
}

// Returns an array of week numbers of the date parameter
// export function getWeekNumbersInMonth(date: Date): number[] {
//   const year = date.getFullYear();
//   const month = date.getMonth();

//   const firstDayOfMonth = new Date(year, month, 1);
//   const lastDayOfMonth = new Date(year, month + 1, 0);

//   const weekNumbers: number[] = [];
//   let currentDay = firstDayOfMonth;

//   while (currentDay <= lastDayOfMonth) {
//     const weekNumber = getWeekNumber(currentDay);
//     if (!weekNumbers.includes(weekNumber)) {
//       weekNumbers.push(weekNumber);
//     }
//     currentDay.setDate(currentDay.getDate() + 1);
//   }

//   return weekNumbers;
// }

// Ensures dates are similar down to their time
export function resetDates(dates: Date[]) {
  return dates.map(date => {
    // Set the time component to midnight for both dates
    date.setHours(0, 0, 0, 0);

    return date.getTime();
  });
}

export function getWeekNumber(date: Date): number {
  const target = new Date(date.getTime());
  target.setHours(0, 0, 0, 0);
  target.setDate(target.getDate() + 4 - (target.getDay() || 7));

  const yearStart = new Date(target.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(((target.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);

  return weekNumber;
}

// Returns an array of week numbers of the date parameter
export function getWeekNumbersInMonth(date: Date): number[] {
  const year = date.getFullYear();
  const month = date.getMonth();
  const today = new Date();

  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);
  const isCurrentMonth = date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();

  const weekNumbers: number[] = [];
  let currentDay = firstDayOfMonth;

  while (currentDay <= lastDayOfMonth) {
    if (isCurrentMonth && currentDay > today) {
      break;
    }
    const weekNumber = getWeekNumber(currentDay);
    if (!weekNumbers.includes(weekNumber)) {
      weekNumbers.push(weekNumber);
    }
    currentDay.setDate(currentDay.getDate() + 1);
  }

  return weekNumbers;
}

export const getExpiry = (expiryDuration: number) => {
  return new Date().getTime() + expiryDuration;
};
