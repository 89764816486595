import { FormEvent, useState, ChangeEvent, useEffect } from "react";
import { useSelector } from "react-redux";
import { CardHeader, Typography, TextField, Box, Grid, Button } from "@mui/material";
import PhoneInput, { CountryData } from "react-phone-input-2";

// import "react-phone-input-2/lib/material.css";
import { ErrorType, TokenResp } from "../../models";
import { RootState } from "../../store";
import { genericErrorMessg } from "../../util";
import { NotificationModal } from "../common/notification-modal";
import { usePostStudentDetailsUpdateMutation } from "../../store/rtk";
import { LoadingSpinner } from "../common/loading-spinner";

interface InitState {
  inputData: string;
  isValid: boolean;
  isTouched: boolean;
  error: null | string;
}

const isLessThan3CharsLong = (value: string) => value.length <= 2;
const phoneNoRegex = /^\+?\d{1,3}[-.\s]?\d{1,14}$/;

interface EditProfileModalProps {
  hideModal: () => void;
}

export const EditProfileModal = ({ hideModal }: EditProfileModalProps) => {
  const userData = useSelector((state: RootState) => state.user);
  const [firstName, setFirstNameInput] = useState<InitState>({
    inputData: userData.enrolleeData.fName,
    isValid: true,
    isTouched: false,
    error: null,
  });
  const [lastName, setLastNameInput] = useState<InitState>({
    inputData: userData.enrolleeData.lName,
    isValid: true,
    isTouched: false,
    error: null,
  });
  const [phoneNo, setPhoneNoInput] = useState<InitState>({
    inputData: userData.enrolleeData.phoneNo,
    isValid: true,
    isTouched: false,
    error: null,
  });
  const [serverError, setServerError] = useState<null | string>(null);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [updateUserDetails, { isLoading, error }] = usePostStudentDetailsUpdateMutation();

  useEffect(() => {
    if (error) {
      // console.log("Error from rtk in useEffect :", error);
      setServerError(`${error}`);
      setShowErrorBanner(true);
    }
  }, [error]);

  const firstNameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    console.log("firstNameChangeHandler : ", e.target.value);
    const trimedInput = e.target.value.trim();
    if (isLessThan3CharsLong(trimedInput)) {
      setFirstNameInput({
        inputData: trimedInput,
        isValid: false,
        isTouched: true,
        error: "first name should be at least 3 characters long",
      });
    } else {
      setFirstNameInput({
        inputData: trimedInput,
        isValid: true,
        isTouched: true,
        error: null,
      });
    }
  };

  const lastNameChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const trimedInput = e.target.value;
    if (isLessThan3CharsLong(trimedInput)) {
      setLastNameInput({
        inputData: trimedInput,
        isValid: false,
        isTouched: true,
        error: "last name should be at least 3 characters long",
      });
    } else {
      setLastNameInput({
        inputData: trimedInput,
        isValid: true,
        isTouched: true,
        error: null,
      });
    }
  };

  const phoneChangeHandler = (phone: string, countryData: CountryData) => {
    const trimmedInput = phone.trim();
    if (phoneNoRegex.test(trimmedInput)) {
      setPhoneNoInput({
        inputData: trimmedInput,
        isValid: true,
        isTouched: true,
        error: "",
      });
    } else {
      setPhoneNoInput({
        inputData: trimmedInput,
        isValid: false,
        isTouched: true,
        error: "Invalid phone number",
      });
    }
  };

  const submitEventHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      fName: firstName.inputData,
      lName: lastName.inputData,
      phoneNo: phoneNo.inputData,
    };

    const sendDataToServer = async () => {
      try {
        const serverRes = (await updateUserDetails(data)) as { data: TokenResp; error: ErrorType };
        if (serverRes.error) {
          setServerError(serverRes.error.data.error.message);
          setShowErrorBanner(true);
          return;
        }

        hideModal();
      } catch (err) {
        setServerError(genericErrorMessg);
        setShowErrorBanner(true);
      }
    };

    sendDataToServer();
  };

  const phoneNoClasses = phoneNo.isValid === false && phoneNo.isTouched === true ? "is-invalid" : "";

  const formIsValid =
    phoneNo.isValid &&
    firstName.isValid &&
    lastName.isValid &&
    (phoneNo.isTouched || firstName.isTouched || lastName.isTouched);

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}
      {isLoading && <LoadingSpinner />}
      <div className="modal fade show" style={{ display: "block", zIndex: 10000 }} tabIndex={-1}>
        <div
          className="modal-backdrop show"
          style={{ zIndex: "auto" }}
          onClick={() => {
            hideModal();
          }}
        ></div>
        <div className="modal-dialog modal-dialog-centered my-0">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="enrollLabel">
                <CardHeader
                  sx={{ pb: 0 }}
                  title={
                    <Typography
                      component="div"
                      sx={{ fontSize: "1.2rem", textTransform: "uppercase", textAlign: "center" }}
                    >
                      Edit user details
                    </Typography>
                  }
                ></CardHeader>
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  hideModal();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <form method="POST" onSubmit={submitEventHandler}>
                <Box
                  sx={{
                    "& .MuiTextField-root": { m: 1 },
                    justifyContent: "center",
                  }}
                  component="div"
                >
                  <Grid container component="div" sx={{ justifyContent: "center", px: 0 }}>
                    <TextField
                      onChange={firstNameChangeHandler}
                      fullWidth
                      defaultValue={userData.enrolleeData.fName}
                      error={!firstName.isValid && firstName.isTouched}
                      name="fName"
                      helperText={<div>{firstName.error}</div>}
                      type="text"
                      label="First name"
                      id="outlined-size-normal"
                    />
                    <TextField
                      onChange={lastNameChangeHandler}
                      type="text"
                      name="lName"
                      defaultValue={userData.enrolleeData.lName}
                      error={!lastName.isValid && lastName.isTouched}
                      fullWidth
                      helperText={<div>{lastName.error}</div>}
                      label="Last name"
                      id="outlined-size-normal"
                    />
                    <Box sx={{ px: 1, py: 0.5, width: "100%" }}>
                      <PhoneInput
                        containerClass={phoneNoClasses}
                        value={phoneNo.inputData}
                        inputClass="phoneNoInputProf"
                        inputProps={{
                          name: "phoneNumber",
                          // value: `${phoneNo.inputData}`,
                          required: true,
                          autoFocus: false,
                        }}
                        country={"ke"}
                        preferredCountries={["ke", "us"]}
                        enableSearch={true}
                        onChange={(phone: string, countryData: CountryData) => {
                          phoneChangeHandler(phone, countryData as CountryData);
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid container sx={{ justifyContent: "center", mt: 2 }}>
                    <Button type="submit" variant="contained" color="success" size="large" disabled={!formIsValid}>
                      submit
                    </Button>
                  </Grid>
                </Box>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
