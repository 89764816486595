import { useSelector, useDispatch } from "react-redux";
import { CardHeader, Typography } from "@mui/material";

import { UpdatesForm } from "./updates-form";
import { updatesSliceActions } from "../../../store/updates-slice";
import { RootState } from "../../../store";

export const CourseUpdatesModal = () => {
  const dispatch = useDispatch();
  const isEditing = useSelector((state: RootState) => state.updates.isEditing);

  const hideModal = () => {
    dispatch(updatesSliceActions.hasGivenUpdates());
  };

  return (
    <>
      <div className="modal fade show" style={{ display: "block", zIndex: 10000 }} tabIndex={-1}>
        <div
          className="modal-backdrop show"
          style={{ zIndex: "auto" }}
          onClick={() => {
            hideModal();
          }}
        ></div>
        <div className="modal-dialog modal-dialog-centered my-0">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="enrollLabel">
                <CardHeader
                  sx={{ pb: 0 }}
                  title={
                    <Typography sx={{ fontSize: "1.2rem", textTransform: "uppercase", textAlign: "center" }}>
                      {isEditing ? "Edit" : "Give"} course update
                    </Typography>
                  }
                ></CardHeader>
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  hideModal();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <UpdatesForm hideModal={hideModal} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
