export const InfoTilesSection = () => {
  return (
    <>
      <section id="info-tiles-section" className="p-5 ">
        <div className="container">
          <div className="row text-center g-4 align-items-center">
            <div className="col-md" data-aos="fade-up">
              <div className="card bg-dark text-light  pb-5">
                <div className="card-body text-center">
                  <div className="h1 mb-3">
                    <i className="bi bi-laptop"></i>
                  </div>
                  <h3 className="card-title mb-3" style={{ fontSize: "1.5em" }}>
                    Hands-on
                  </h3>
                  <p className="card-text" style={{ fontSize: "1.15em" }}>
                    Learn better by doing. In addition to the weekly assignments, you will be working on your own coding
                    project you can add to your portfolio. This is to help you cement the skills gained and propel you
                    to greater heights
                  </p>
                  {/* <Link to="#" className="btn btn-success">
                    Read More
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-md" data-aos="fade-up" data-aos-delay="200">
              <div className="card bg-success text-light  pb-5">
                <div className="card-body text-center">
                  <div className="h1 mb-3">
                    {" "}
                    <i className="bi bi-people"></i>{" "}
                  </div>
                  <h3 className="card-title mb-3" style={{ fontSize: "1.5em" }}>
                    Hybrid
                  </h3>
                  <p className="card-text" style={{ fontSize: "1.15em" }}>
                    You are not alone,You'll get to meet with intelligent, top performers programmers whom you'll
                    collaborate with on projects and do peer-programming, to come up with state-of-the-art products that
                    the world is looking for!
                  </p>
                  {/* <Link to="#" className="btn btn-dark">
                    Read More
                  </Link> */}
                </div>
              </div>
            </div>
            <div className="col-md " data-aos="fade-up" data-aos-delay="400">
              <div className="card bg-dark text-light  pb-5">
                <div className="card-body text-center">
                  <div className="h1 mb-3">
                    {" "}
                    <i className="bi bi-bank"></i>{" "}
                  </div>
                  <h3 className="card-title mb-3" style={{ fontSize: "1.5em" }}>
                    End Goal
                  </h3>
                  <p className="card-text" style={{ fontSize: "1.15em" }}>
                    Get one on one career coaching, access to an exclusive job board and career development course, and
                    hands-on help with your resume and LinkedIn recommendations to help you get your dream job.
                    <span className="text-info">Don't be left behind!</span>
                  </p>
                  {/* <Link to="#" className="btn btn-success">
                    Read More
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
