import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

import { UserHistoryBreadcrumbs, NotificationModal } from "..";
import { LoadingSpinnerForTable } from "./loading-spinner";
import { ErrorType, GetTrackerResp } from "../../models";
import { useGetTrackersMutation } from "../../store/rtk";
import { RootState } from "../../store";

interface UserHistoryProps {
  isAdmin: boolean;
  student?: string;
  enrollmentId?: number;
}

export const UserHistory = ({ isAdmin, student, enrollmentId }: UserHistoryProps) => {
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [getTrackers, { isLoading, error }] = useGetTrackersMutation();
  const [courses, setCourses] = useState<[] | GetTrackerResp[]>([]);
  const params = useParams();
  const navigate = useNavigate();
  const userData = useSelector((state: RootState) => state.user);

  const itemsPerPage = 5;

  useEffect(() => {
    if (error) {
      setServerError(`${error}`);
      setShowErrorBanner(true);
    }
  }, [error]);

  useEffect(() => {
    if ((params && params.user) || enrollmentId) {
      const sendDataToServer = async () => {
        try {
          const enrollementId = Number(params.user) || Number(enrollmentId);
          // console.log("EnrollMent : ", enrollementId);
          const serverRes = (await getTrackers({ enrollementId })) as { data: GetTrackerResp[]; error: ErrorType };
          if (serverRes.error) {
            return;
          }

          setCourses(serverRes.data);
        } catch (err) {
          setServerError(`Unable to fetch tracked courses`);
          setShowErrorBanner(true);
        }
      };

      sendDataToServer();
    }
  }, [getTrackers, params, enrollmentId]);

  function CourseRow({ courses }: { courses: GetTrackerResp[] }) {
    return (
      <>
        {courses &&
          courses.map((course, index) => {
            let path = `/progress/history`;
            if (isAdmin) {
              path = `/progress-admin/history/${params.user}/${course.courseId}`;
            } else {
              path = `/progress/history/${userData?.currentTracker?.id}`;
            }
            return (
              <tr
                key={course.id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  localStorage.setItem("selectedCourse", course.course.name);
                  navigate(path);
                }}
              >
                <td className="py-3">{courses.findIndex(cour => cour.id === course.id) + 1}</td>
                <td className="py-3">{course.course.name}</td>
                <td className="py-3">{course.course.totalLecNo}</td>
                <td className="py-3">
                  <div style={{ textAlign: "center", maxWidth: "4rem" }}>
                    {
                      <CircleRoundedIcon
                        sx={{ fill: course.isActive ? "#1CC88A" : "red", width: "1rem", height: "1rem" }}
                      />
                    }
                  </div>
                </td>
                <td className="py-3">
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: course.progress ? `${course.progress}%` : "0%" }}
                      aria-valuenow={course.progress ? +course.progress : 0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    {course.progress ? `${course.progress}%` : "0%"}
                  </div>
                </td>
              </tr>
            );
          })}
      </>
    );
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentCourses = courses.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(courses.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % courses.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}
      <Box
        component="div"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: isAdmin ? "" : "1rem", mb: "3rem", mx: isAdmin ? 0 : { xs: 0, sm: 0, md: 5, lg: 10, xl: 10 } }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            py: isAdmin ? 2 : 3,
            backgroundColor: "ghostwhite",
          }}
        >
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <WorkHistoryIcon sx={{ fontSize: "1.5rem" }} />
              <Typography
                ml={2}
                fontWeight={"600"}
                fontSize="medium"
                component="h1"
                textTransform={"uppercase"}
                sx={{ fontSize: "1.25rem", color: "#2068BC" }}
              >
                Course update history
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={5} lg={5} xl={5} item sx={{ mt: { xs: 2, sm: 2, md: 0, lg: 0, xl: 0 } }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <UserHistoryBreadcrumbs isAdmin={isAdmin} userParam={Number(params.user) || enrollmentId} />
            </Box>
          </Grid>
        </Grid>

        <Divider />
        <div className="container-fluid remTablePaddingX" style={{ marginTop: "24px", minHeight: "70vh" }}>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card-body shadow">
                <div className="table-responsive" style={{ minHeight: "15rem" }}>
                  <table className="table  table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Course Name</th>
                        <th>Total Lectures</th>
                        <th>Active Status</th>
                        <th>Progress</th>
                      </tr>
                    </thead>
                    <tbody style={{ color: "#505156" }}>
                      {isLoading && <LoadingSpinnerForTable />}
                      {<CourseRow courses={currentCourses} />}
                    </tbody>
                  </table>
                </div>

                <div className="row">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                      <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
      </Box>
    </>
  );
};
