/* eslint-disable jsx-a11y/anchor-is-valid */
import { Helmet } from "react-helmet";

export const AdminInquiryTab = () => {
  return (
    <>
      <Helmet>
        <title>Inquiry</title>
      </Helmet>
      <div className="container-fluid " style={{ marginTop: 100 }}>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-header">
                <h4>Inquiries</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Subject</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="py-1">1</td>
                        <td>Bond james</td>
                        <td>271. Linux Fundamentals</td>
                        <td>Linux Course</td>
                      </tr>
                      <tr>
                        <td className="py-1">2</td>
                        <td>Bond james</td>
                        <td>271. Linux Fundamentals</td>
                        <td>Linux Course</td>
                      </tr>
                      <tr>
                        <td className="py-1">3</td>
                        <td>Bond james</td>
                        <td>271. Linux Fundamentals</td>
                        <td>Linux Course</td>
                      </tr>
                      <tr>
                        <td className="py-1">4</td>
                        <td>Bond james</td>
                        <td>271. Linux Fundamentals</td>
                        <td>Linux Course</td>
                      </tr>
                      <tr>
                        <td className="py-1">5</td>
                        <td>Bond james</td>
                        <td>271. Linux Fundamentals</td>
                        <td>Linux Course</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                      <li className="page-item disabled">
                        <a className="page-link" href="#" tabIndex={-1} aria-disabled="true">
                          Previous
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#">
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
