import { useEffect, useState, useCallback, useRef } from "react";
import Calendar from "react-calendar";
import { Grid, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import "../../assets/css/calendar.css";
import { ActiveStudents, ErrorType } from "../../models";
import { NotificationModal } from "../common/notification-modal";
import { getWeekNumbersInMonth } from "../../util";
import { useGetAttendanceScoreMutation } from "../../store/rtk/admin-api";

interface AttendanceScoreCarlenderModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMember: ActiveStudents | null;
}

const now = new Date();

export const AttendanceScoreCarlenderModal = ({
  show,
  selectedMember,
  setShow,
}: AttendanceScoreCarlenderModalProps) => {
  const [currentDate, setCurrentDate] = useState<Date>(now);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const [isFetchingScores, setIsFetchingScores] = useState(false);
  const scoresHaveBeenFetched = useRef<boolean>(false);
  const [year, setYear] = useState<number>(now.getUTCFullYear());
  const [month, setMonth] = useState<number>(now.getUTCMonth());
  const [attendanceScores, setAttendanceScores] = useState<[] | number[]>([]);
  const [getAttendanceScore, { error }] = useGetAttendanceScoreMutation();

  useEffect(() => {
    if (error) {
      setServerError(`${error}`);
      setShowErrorBanner(true);
    }
  }, [error]);

  const fetchAttendanceScore = useCallback(
    async (week: number, year: number) => {
      try {
        const data = { enrollmentId: selectedMember!.id, week, year };
        const serverRes = (await getAttendanceScore(data)) as { data: number; error: ErrorType };

        if (serverRes.error) {
          setServerError(serverRes.error.data.error.message);
          setShowErrorBanner(true);
          (scoresHaveBeenFetched as { current: boolean }).current = true;
          return;
        }

        return serverRes;
      } catch (err) {
        setServerError(`Unable to fetch weekly attendance scores`);
        setShowErrorBanner(true);
      }
    },
    [getAttendanceScore, selectedMember]
  );

  useEffect(() => {
    const weekNumsThisMonth = getWeekNumbersInMonth(currentDate);
    const enrollmentDate = new Date(selectedMember!.enrollmentDate);

    if (
      !scoresHaveBeenFetched.current &&
      month >= enrollmentDate.getUTCMonth() &&
      month <= enrollmentDate.getUTCMonth()
    ) {
      setIsFetchingScores(true);

      const fetchScores = async () => {
        try {
          for (const week of weekNumsThisMonth) {
            const attScore = await fetchAttendanceScore(week, year);

            if (attScore?.error) {
              setAttendanceScores([]);
              setServerError(`${attScore?.error}`);
              setShowErrorBanner(true);
              break;
            } else if (attScore?.data !== undefined && attScore?.data !== null) {
              setAttendanceScores(prevState => [...prevState, attScore!.data as number]);
            }
          }
        } catch (error) {
          (scoresHaveBeenFetched as { current: boolean }).current = true;
          console.log("Catch loop error : ", error);
          setServerError(`Unable to fetch weekly attendance scores`);
          setShowErrorBanner(true);
        }
      };
      fetchScores();
      scoresHaveBeenFetched.current = true;

      setIsFetchingScores(false);
    }
  }, [currentDate, fetchAttendanceScore, error, attendanceScores, selectedMember, month, year]);

  const handleClose = () => {
    setShow(false);
  };

  const scoresArePresent =
    !isFetchingScores &&
    Array.isArray(attendanceScores) &&
    attendanceScores.length > 0 &&
    scoresHaveBeenFetched.current;
  let scoreList;
  if (scoresArePresent) {
    scoreList = attendanceScores.map((score, i) => (
      <div
        className="react-calendar__tile react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend"
        key={i}
        style={{
          width: "max-content",
          marginTop: "0.36rem",
          marginLeft: "0.5rem",
        }}
      >
        {`${score}%` || `---`}
      </div>
    ));
  } else {
    scoreList = (
      <Typography sx={{ marginTop: "4.5rem" }} color="red">
        Fetching attendance is not allowed for date before / after the enrollment date of the student
      </Typography>
    );
  }

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}
      <Dialog open={show} onClose={handleClose} fullWidth>
        <DialogContent sx={{ pt: 2, px: 1, width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Calendar
                className={["calendar-tile-mth"]}
                minDetail="month"
                showNavigation={isFetchingScores ? false : true}
                showNeighboringMonth={false}
                // tileClassName={setTileClassName}
                onActiveStartDateChange={({ action, activeStartDate, value, view }) => {
                  // Clear the attendance scores when view changes
                  (scoresHaveBeenFetched as { current: boolean }).current = false;
                  setAttendanceScores([]);
                  setCurrentDate(activeStartDate!);
                  setYear(activeStartDate!.getFullYear());
                  setMonth(activeStartDate!.getMonth());
                  // On every view change set the current date to the activeStartDate
                }}
                defaultValue={new Date()}
                // value={value as Date}
              />
            </Grid>
            <Grid item xs={4}>
              <div
                className="react-calendar__month-view__weekdays__weekday--weekend"
                style={{
                  marginTop: "62px",
                  fontSize: "1.05rem",
                  textTransform: "uppercase",
                  borderBottom: "1.5px solid #4E73DF",
                  fontWeight: "bold",
                  color: "#4E73DF",
                  width: "11rem",
                }}
              >
                Attendance Score
              </div>
              <div>{scoreList}</div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
