import jwt_decode from "jwt-decode";
import { DecodedTokenStructure } from "../models";

export const decodeToken = (token: string) => {
  try {
    const decodedToken = jwt_decode(token) as DecodedTokenStructure;

    return decodedToken;
  } catch (err) {
    // console.log("Decoding Error : ", err);
  }
};

export function groupItemsIntoChunks<T>(items: T[], chunkSize: number): T[][] {
  const result: T[][] = [];
  const totalChunks = Math.ceil(items.length / chunkSize);

  for (let i = 0; i < totalChunks; i++) {
    const startIndex = i * chunkSize;
    const endIndex = startIndex + chunkSize;
    result.push(items.slice(startIndex, endIndex));
  }

  return result;
}

export const genericErrorMessg = "Unable to process your request";

export * from "./lec-number-count";
export * from "./chart-data-updater";
export * from "./string-text";
export * from "./dates";
export * from "./refreshFunc";
