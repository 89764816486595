const ContactUsHeader = () => {
  return (
    <div className="col-12">
      <div className="title text-center">
        <h2>
          Get In <span className="text-warning">Touch</span>
        </h2>
        <div className="border"></div>
      </div>
    </div>
  );
};

export default ContactUsHeader;
