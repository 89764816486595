import { useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ReactPaginate from "react-paginate";

import { EnrolleeData } from "../../models";
import { LoadingSpinnerWithoutBackDrop } from "../common/loading-spinner";
import { Box, Typography } from "@mui/material";

interface AlumniModalProps {
  alumni: EnrolleeData[];
  isLoading: boolean;
}

export const AlumniModal = ({ alumni, isLoading }: AlumniModalProps) => {
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;

  function AlumniList({ alumni }: { alumni: EnrolleeData[] }) {
    return (
      <>
        {alumni &&
          Array.isArray(alumni) &&
          alumni.map(alumnus => {
            const labelId = `checkbox-list-secondary-label-${alumnus.email}`;
            return (
              <ListItem sx={{ py: 1 }} key={alumnus.email} disablePadding>
                <ListItemButton disableRipple>
                  <ListItemAvatar>
                    <Avatar src={alumnus.fName} sx={{ backgroundColor: "#4262BE" }}></Avatar>
                  </ListItemAvatar>
                  <ListItemText id={labelId} primary={`${alumnus.fName} ${alumnus.lName}`} />
                </ListItemButton>
              </ListItem>
            );
          })}
      </>
    );
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentAlumni = alumni.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(alumni.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % alumni.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="modal fade" id="viewAlumni" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Alumni
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body my-5 py-5">
              <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
                {isLoading && (
                  <div style={{ textAlign: "center" }}>
                    <LoadingSpinnerWithoutBackDrop />
                  </div>
                )}

                {Array.isArray(alumni) && <AlumniList alumni={currentAlumni} />}
                {Array.isArray(alumni) && !isLoading && (
                  <Box sx={{ textAlign: "center", fontSize: "1.1rem" }}>
                    <Typography>No alumni found</Typography>
                  </Box>
                )}
              </List>

              {alumni.length > 5 && (
                <div className="row">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                      <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </ul>
                  </nav>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
