export function shortenString(str: string, length: number): string {
  if (str.length <= length) {
    return str;
  } else {
    return str.slice(0, length) + " ...";
  }
}

export function replaceSpacesWithHyphen(inputString: string) {
  let transformedString = "";

  for (let i = 0; i < inputString.length; i++) {
    if (inputString[i] === " ") {
      transformedString += "-";
    } else {
      transformedString += inputString[i];
    }
  }

  return transformedString;
}

export function replaceHyphenWithSpace(inputString: string) {
  let transformedString = "";

  for (let i = 0; i < inputString.length; i++) {
    if (inputString[i] === "-") {
      transformedString += " ";
    } else {
      transformedString += inputString[i];
    }
  }

  return transformedString;
}
