import values1 from "../../assets/images/about/values-1.png";
import values2 from "../../assets/images/about/values-2.png";
import values3 from "../../assets/images/about/values-3.png";

export const ValuesSection = () => {
  return (
    <section id="values" className="values">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Our Values</h2>
          <p>Encouraging collaboration, transparency and empowerment of others</p>
        </header>

        <div className="row">
          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div className="box">
              <img src={values1} className="img-fluid" alt="" />
              <h3>Strive for Excellence</h3>
              <p id="utilSize">Excellency is what defines us, it's encoded in our DNA</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
            <div className="box">
              <img src={values2} className="img-fluid" alt="" />
              <h3>Promote ingenuity</h3>
              <p id="utilSize">
                We strive to foster an environment that inspires our students to think outside the box and come up with
                innovative solutions to real-world problems.
              </p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
            <div className="box">
              <img src={values3} className="img-fluid" alt="" />
              <h3>Practice life long learning</h3>
              <p id="utilSize">Build up on the knowledge gained to propel you to greater heights career-wise .</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
