import featuresPic from "../../assets/images/about/features.png";
import features2 from "../../assets/images/about/features-2.png";

export const FeaturesSection = () => {
  return (
    <section id="features" className="features">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Vision</h2>
          <p>
            A world where anyone with determination can excel in the IT industry with world class skills and expertise
            while unhindered by financial background
          </p>
        </header>

        <div className="row">
          <div className="col-lg-6">
            <img src={featuresPic} className="img-fluid" alt="" />
          </div>

          <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
            <div className="row align-self-center gy-4">
              <div className="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Collaboration</h3>
                </div>
              </div>

              <div className="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Excellence</h3>
                </div>
              </div>

              <div className="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Transparency</h3>
                </div>
              </div>

              <div className="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Resilience</h3>
                </div>
              </div>

              <div className="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Hard work</h3>
                </div>
              </div>

              <div className="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Work smart</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- / row ----------------------- */}

        {/* ---------------- Feature Tabs ----------------------- */}
        <div id="mission" className="row feture-tabs" data-aos="fade-up">
          <div className="col-lg-6">
            <h3 style={{ fontSize: "2.35rem" }}>Mission</h3>

            {/* ------------- Tabs ----------- */}
            <ul className="nav nav-pills mb-3">
              <li>
                <a
                  className="nav-link "
                  data-bs-toggle="pill"
                  href="#tab1"
                  style={{
                    backgroundColor: "none",
                    color: "#4154f1",
                    borderBottom: "3px solid #4154f1",
                    padding: "0.375rem 0",
                  }}
                >
                  <span style={{ fontSize: "1.25rem" }}>Mission</span>
                </a>
              </li>
            </ul>
            {/* ------------- End Tabs ------- */}

            {/* ----------- Tab Content -------------- */}
            <div className="tab-content">
              <div className="tab-pane fade show active" id="tab1">
                <p id="utilSize">Empowering tomorrow's IT professionals through education.</p>
                <div className="d-flex align-items-center mb-2">
                  <i className="bi bi-check2"></i>
                  <h4>Road map</h4>
                </div>
                <p id="utilSize">We firmly believe in a community that is educative, empowering, and goal-oriented</p>
                <div className="d-flex align-items-center mb-2">
                  <i className="bi bi-check2"></i>
                  <h4>Advocacy</h4>
                </div>
                <p id="utilSize">Tech savvy and modernized way of ideation and programming</p>
              </div>
              {/* -------------- End Tab 1 Content -------------- */}
            </div>
          </div>

          <div className="col-lg-6">
            <img src={features2} className="img-fluid" alt="" />
          </div>
        </div>
        {/* -------------- End of Tabs -------------- */}
      </div>
    </section>
  );
};
