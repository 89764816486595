import { RootState } from "../../../store";
import { useSelector, useDispatch } from "react-redux";
import { updatesSliceActions } from "../../../store/updates-slice";
import { CardContent, Card, CardHeader, Typography, Grid, Button } from "@mui/material";

const CourseUpdates = () => {
  const userData = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();

  const editLectureHandler = () => {
    dispatch(updatesSliceActions.isEditingUpdate());
  };

  const lectureDetails = `${userData.todayUpdate?.curLecNo}. ${userData.todayUpdate?.comment}`;

  return (
    <>
      <Card
        elevation={1}
        sx={{ overflow: "clip", borderRadius: "4px 4px 1rem 1rem", border: "1px solid #c8c8c8", p: 0 }}
      >
        <CardHeader
          sx={{ pt: "0.25rem", pb: "0" }}
          title={
            <Typography sx={{ fontSize: "1.05rem", textTransform: "uppercase", fontWeight: 400 }}>
              Today's update
            </Typography>
          }
        ></CardHeader>
        <CardContent>
          <Grid container sx={{ justifyContent: "space-between", display: "flex" }}>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: "1rem" }}>{lectureDetails}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" color="info" size="small" onClick={editLectureHandler}>
                edit
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default CourseUpdates;
