import { v4 as uuidv4 } from "uuid";
// import bcryptjs from "bcryptjs";
import { DOMAIN } from "./domains";

// const codeChallenge = bcryptjs.hashSync(codeVerifier, 10);
export const REFRESH_BEFORE = 2 * 60 * 1000;

export const origin = `${DOMAIN}/login`;

export const getBody = (clientId?: string) => {
  const codeVerifier = setCodeVerifier();
  return {
    clientId: clientId ? clientId : (localStorage.getItem("clientId") as string),
    redirectURI: `${window.location.origin}/auth/callback`,
    codeVerifier: codeVerifier,
    // codeChallenge: bcryptjs.hashSync(codeVerifier, 10),
  };
};

export function setCodeVerifier() {
  const existingCode = localStorage.getItem("codeVerifier");

  if (existingCode) {
    // console.log("Existing code: ", existingCode);
    return existingCode;
  }

  const codeVerifier = uuidv4();
  // console.log("new code verifier: ", codeVerifier);
  localStorage.setItem("codeVerifier", codeVerifier);
  return codeVerifier;
}
