import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type E = { error: { message: string } };
const initialState: E[] = [];

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError(state, action: PayloadAction<E>) {
      state.push(action.payload);
    },
    clearError(state, action: PayloadAction<number>) {
      const currIndex = state.findIndex((_, index) => index === action.payload);
      state.splice(currIndex, 1);
    },
  },
});

export const errorActions = errorSlice.actions;
export const errorReducer = errorSlice.reducer;
