import { useState, useEffect } from "react";
import { Box, Typography, Divider, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import ReactPaginate from "react-paginate";

import { UserHistoryBreadcrumbs, NotificationModal } from "..";
import { LoadingSpinnerForTable } from "./loading-spinner";
import { formatDate, shortenString } from "../../util";
import { useGetUpdatesMutation } from "../../store/rtk";
import { ErrorType, UpdateData } from "../../models";

interface CourseHistoryProps {
  isAdmin: boolean;
  student?: string;
  course?: string;
}

export const CourseHistory = ({ isAdmin, student }: CourseHistoryProps) => {
  const params = useParams();
  const [itemOffset, setItemOffset] = useState(0);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const [updates, setUpdates] = useState<[] | UpdateData[]>([]);
  const [getUpdates, { isLoading, error }] = useGetUpdatesMutation();
  const course = shortenString(localStorage.getItem("selectedCourse")! as string, 30);

  useEffect(() => {
    if (error) {
      setServerError(`${error}`);
      setShowErrorBanner(true);
    }
  }, [error]);

  const itemsPerPage = 5;

  useEffect(() => {
    if (params && params.user) {
      const sendDataToServer = async () => {
        try {
          const trackerId = Number(params.user);
          const serverRes = (await getUpdates({ trackerId })) as { data: UpdateData[]; error: ErrorType };
          if (serverRes.error) {
            setUpdates([]);
            return;
          }

          if (serverRes.data) setUpdates(serverRes.data);
        } catch (err) {
          setUpdates([]);
          // console.error("Fetch updates Error : ", err);
          setServerError(`Unable to fetch updates for this course`);
          setShowErrorBanner(true);
        }
      };

      sendDataToServer();
    }
  }, [getUpdates, params]);

  function UpdateRows({ updates }: { updates: UpdateData[] }) {
    return (
      <>
        {updates &&
          updates.map((update, index) => {
            return (
              <tr key={update.id}>
                <td className="py-3">{updates.findIndex(upd => upd.id === update.id) + 1}</td>
                <td className="py-3">{update.comment}</td>
                <td className="py-3">{update.curLecNo}</td>
                <td className="py-3">{formatDate(new Date(update.postedOn))}</td>
              </tr>
            );
          })}
      </>
    );
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentUpdates = updates.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(updates.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % updates.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}
      <Box
        component="div"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: "1rem", mb: "3rem", mx: isAdmin ? 0 : { xs: 0, sm: 0, md: 5, lg: 10, xl: 10 } }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            py: isAdmin ? 3 : 4,
            backgroundColor: "ghostwhite",
          }}
        >
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <CastForEducationIcon sx={{ fontSize: "1.5rem" }} />
              <Typography
                ml={2}
                fontWeight={"600"}
                fontSize="medium"
                component="h1"
                textTransform={"uppercase"}
                sx={{ fontSize: "1.25rem", color: "#2068BC" }}
              >
                {course}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={5} lg={5} xl={5} item sx={{ mt: { xs: 2, sm: 2, md: 0, lg: 0, xl: 0 } }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <UserHistoryBreadcrumbs
                course={shortenString(localStorage.getItem("selectedCourse")! as string, 20)}
                isAdmin={isAdmin}
                student={student}
                userParam={Number(params.user)}
              />
            </Box>
          </Grid>
        </Grid>
        <Divider />

        <div className="container-fluid remTablePaddingX" style={{ marginTop: "24px", minHeight: "70vh" }}>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card-body shadow">
                <div className="table-responsive" style={{ minHeight: "15rem" }}>
                  <table className="table  table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Course Title</th>
                        <th>Lecture Number </th>
                        <th>Posted on</th>
                      </tr>
                    </thead>
                    <tbody style={{ color: "#505156" }}>
                      {isLoading && <LoadingSpinnerForTable />}
                      {<UpdateRows updates={currentUpdates} />}
                    </tbody>
                  </table>
                </div>

                <div className="row">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                      <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};
