import feature2 from "../../assets/images/about/features-2.png";

export const LatestHighLights = () => {
  return (
    <>
      <section id="learn" className="p-5 bg-dark text-light">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div id="learnInfo" className="col-md p-5" data-aos="zoom-out-right" data-aos-delay="200">
              <h2>Latest Highlights</h2>
              <p id="lead" className="lead" style={{ fontSize: "1.375em" }}>
                <span className="text-info">Did you Know</span> React makes it painless to create interactive UIs.
                Design simple views for each state in your application, and React will efficiently update and render
                just the right components when your data changes.
              </p>
              <p id="utilSize">
                Declarative views make your code more predictable and easier to debug.Build encapsulated components that
                manage their own state, then compose them to make complex UIs. Since component logic is written in
                JavaScript instead of templates, you can easily pass rich data through your app and keep state out of
                the DOM.
              </p>
              {/* <Link to="#" className="btn btn-success mt-3">
                <i className="bi bi-chevron-right"></i> Read More
              </Link> */}
            </div>
            <div className="col-md" data-aos="zoom-out-left" data-aos-delay="200">
              <img src={feature2} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
