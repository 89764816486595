import { Outlet } from 'react-router-dom';
import { NavBar,Footer } from '../components';

export const RootLayout = () => {
  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
};
