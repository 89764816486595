import React from "react";
import { Helmet } from "react-helmet";

import "../assets/css/about.css";
import { FaqSection, FeaturesSection, IntroSection, ValuesSection } from "../components";

export const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About us</title>
      </Helmet>

      <div className="section--container">
        {/* -------------  Intro Section  --------------- */}
        <IntroSection />

        {/* --------- --- Values Section --------------- */}
        <ValuesSection />

        {/* ------------- Features Section ---------------- */}
        <FeaturesSection />

        {/* ------------------  FAQ ------------------ */}
        <FaqSection />
        {/* ------------------ End FAQ ------------------ */}
      </div>
    </>
  );
};

export default AboutUs;
