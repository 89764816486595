import { useEffect, useRef } from "react";
import * as echarts from "echarts";

interface DonutChartProps {
  data: { value: number; name: string }[];
}

const DonutChart = ({ data }: DonutChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const chartInstance = echarts.init(chartRef.current!);

    const options: echarts.EChartsOption = {
      tooltip: {},
      legend: {
        data: Array.isArray(data) ? data.map(({ name }) => name) : [],
        // data: [],
      },
      series: [
        {
          name: "Data",
          type: "pie",
          // radius: ["65%", "85%"], // specify the radius range for a donut chart
          radius: ["60%", "80%"],
          avoidLabelOverlap: true,
          label: {
            show: false,
            position: "center",
          },
          tooltip: { trigger: "item" },
          emphasis: {
            label: {
              show: true,
              fontSize: "30",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: data,
        },
      ],
    };

    chartInstance.setOption(options);

    return () => {
      chartInstance.dispose();
    };
  }, [data]);

  return <div style={{ height: 275, textAlign: "center" }} ref={chartRef} />;
};

export default DonutChart;
