import { useState } from "react";
import ReactPaginate from "react-paginate";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";

import { EnrolleeData, ErrorType } from "../../models";
import { LoadingSpinnerForTable } from "../common/loading-spinner";
import { useDeleteEnrollmentMutation } from "../../store/rtk/admin-api";
import { NotificationModal } from "../common/notification-modal";

interface AdminRejectedEnrolleesTableProps {
  rejectedEnrollees: EnrolleeData[];
  modalDataUseState: [string, React.Dispatch<React.SetStateAction<string>>];
  isLoading: boolean;
}

export const AdminRejectedEnrolleesTable = ({
  rejectedEnrollees,
  modalDataUseState,
  isLoading,
}: AdminRejectedEnrolleesTableProps) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const [deleteEnrollment, { isLoading: isDeletingEnrollment }] = useDeleteEnrollmentMutation();
  const itemsPerPage = 5;

  const deleteEnrollee = (id: number) => {
    const sendDataToServer = async () => {
      const serverRes = (await deleteEnrollment({
        enrollmentId: id,
      })) as { data: string; error: ErrorType };

      if (serverRes.error) {
        setServerError(serverRes.error.data.error.message);
        setShowErrorBanner(true);
        return;
      }
    };

    sendDataToServer();
  };

  function EnrolleeRow({ rejectEnrollees }: { rejectEnrollees: EnrolleeData[] }) {
    return (
      <>
        {rejectEnrollees &&
          rejectEnrollees!.map((enrollee, index) => (
            <tr key={enrollee.id}>
              <td className="py-1">{rejectedEnrollees.findIndex(user => user === enrollee) + 1}</td>
              <td>{`${enrollee.fName} ${enrollee.lName}`}</td>
              <td>{enrollee.email}</td>
              <td>{enrollee.phoneNo}</td>
              <td>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    deleteEnrollee(enrollee.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </td>
              {/* <td>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#resumeModal"
                  onClick={() => setModalData(enrollee.resumeUrl)}
                >
                  View
                </button>
              </td> */}
            </tr>
          ))}
      </>
    );
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentEnrollees = rejectedEnrollees.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(rejectedEnrollees.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % rejectedEnrollees.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}

      <div className="container-fluid " style={{ marginTop: 25, paddingLeft: 10, paddingRight: 10 }}>
        <div className="row">
          <div className="col-lg-12 grid-margin stretch-card">
            <div className="card shadow" style={{ border: "1px solid #dc3545" }}>
              <div className="card-header" style={{ backgroundColor: "#F27582" }}>
                <h4>Rejected Enrollees</h4>
              </div>
              <div className="card-body">
                <div className="table-responsive" style={{ minHeight: "15rem" }}>
                  <table className="table table-striped table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>email</th>
                        <th>Phone No.</th>
                        <th>Delete</th>
                        {/* <th>Resume</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {(isLoading || isDeletingEnrollment) && <LoadingSpinnerForTable />}
                      <EnrolleeRow rejectEnrollees={currentEnrollees} />
                      {!isLoading && isDeletingEnrollment && rejectedEnrollees.length === 0 && (
                        <tr
                          style={{
                            backgroundColor: "#fff",
                            height: "100%",
                            border: "none",
                            borderColor: "transparent",
                            fontSize: "1.2rem",
                          }}
                        >
                          <td colSpan={100} style={{ textAlign: "center", backgroundColor: "#fff" }}>
                            No members found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="row">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                      <ReactPaginate
                        nextLabel="next >"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="< previous"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={null}
                      />
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
