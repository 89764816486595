import { useSelector } from "react-redux";
import { shortenString } from "../../../util";
import { Scrollbars } from "react-custom-scrollbars-2";
import { RootState } from "../../../store";

const CourseCompletedCard = () => {
  const userData = useSelector((state: RootState) => state.user);

  return (
    <>
      <Scrollbars style={{ height: 210 }}>
        <ul>
          {userData.completedTrackers.map(completedTracker => (
            <li key={completedTracker?.id} className="list-item" style={{ fontSize: "1.05rem" }}>
              {shortenString(completedTracker?.course?.name, 35)}
            </li>
          ))}
        </ul>
        {userData.completedTrackers.length === 0 && (
          <div style={{ display: "flex", alignContent: "center", justifyContent: "center", fontSize: "1.08rem" }}>
            No course has been completed
          </div>
        )}
      </Scrollbars>
    </>
  );
};

export default CourseCompletedCard;
