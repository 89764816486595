import { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";
import ReactPaginate from "react-paginate";

import { ActiveStudents, ErrorType, RemCourse } from "../../models";
import { CourseListItem } from "./course-list-item";
import { useGetPendingCoursesMutation, useCreateTrackerMutation } from "../../store/rtk/admin-api";
import { NotificationModal } from "../common/notification-modal";
import { LoadingSpinner } from "../common/loading-spinner";

interface AssignCourseModalProps {
  hideModal: () => void;
  selectedEnrollee: ActiveStudents | null;
}

export const AssignCourseModal = ({ selectedEnrollee, hideModal }: AssignCourseModalProps) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [selectedCourse, setSelectedCourse] = useState<null | RemCourse>(null);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [serverError, setServerError] = useState<null | string>(null);
  const [fetchedCourses, setFetchedCourses] = useState<RemCourse[] | []>([]);
  const [getPendingCourses, { isLoading: isGettingPendingCourses }] = useGetPendingCoursesMutation();
  const [createTracker, { isLoading: isCreatingTracker }] = useCreateTrackerMutation();
  const itemsPerPage = 3;

  const onPressCourseListItem = (course: RemCourse) => {
    setSelectedCourse(course);
  };

  function CourseCards({ courses }: { courses: RemCourse[] }) {
    return (
      <>
        {courses &&
          (courses.map(course => (
            <CourseListItem
              key={course.id}
              selectedCourse={selectedCourse}
              index={fetchedCourses.findIndex(fetchCourse => fetchCourse === course) + 1}
              currentCourse={course}
              onPress={onPressCourseListItem}
            />
          )) as JSX.Element[])}
      </>
    );
  }

  const endOffset = itemOffset + itemsPerPage;
  const currentCourses = fetchedCourses.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(fetchedCourses.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsPerPage) % fetchedCourses.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const sendDataToServer = async () => {
      const serverRes = (await getPendingCourses({ enrollmentId: selectedEnrollee!.id })) as {
        data: RemCourse[];
        error: ErrorType;
      };
      if (serverRes.error) {
        setServerError(serverRes.error.data.error.message);
        setShowErrorBanner(true);
        return;
      }
      setFetchedCourses(serverRes.data);
    };

    sendDataToServer();
  }, [getPendingCourses, selectedEnrollee]);

  const assignCourse = () => {
    const sendDataToServer = async () => {
      const serverRes = (await createTracker({
        enrollmentId: selectedEnrollee!.id,
        courseId: selectedCourse!.id,
      })) as { data: string; error: ErrorType };

      if (serverRes.error) {
        setServerError(serverRes.error.data.error.message);
        setShowErrorBanner(true);
        return;
      }
      hideModal();
    };

    sendDataToServer();
  };

  return (
    <>
      {serverError && showErrorBanner && (
        <NotificationModal
          onClose={() => {
            setShowErrorBanner(false);
            setServerError(null);
          }}
          message={serverError as string}
          show={true}
          success={false}
        />
      )}

      <div className="modal fade show" style={{ display: "block", zIndex: 8000 }} tabIndex={-1}>
        <div
          className="modal-backdrop show"
          style={{ zIndex: "auto" }}
          onClick={() => {
            hideModal();
          }}
        ></div>
        <div className="modal-dialog modal-dialog-centered my-0" style={{ maxWidth: "600px" }}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {` Assign Course to ${selectedEnrollee?.fName} ${selectedEnrollee?.lName}`}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  hideModal();
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body mt-1">
              {isGettingPendingCourses && (
                <div className="text-center">
                  <ScaleLoader id="adminScaleLoader" color="#4a6fdc" height={80} width={15} margin={5} radius={10} />
                </div>
              )}
              {isCreatingTracker && <LoadingSpinner />}
              {fetchedCourses && <CourseCards courses={currentCourses} />}
            </div>
            {fetchedCourses.length > 3 && (
              <div className="row">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-end">
                    <ReactPaginate
                      nextLabel="next >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={3}
                      marginPagesDisplayed={2}
                      pageCount={pageCount}
                      previousLabel="< previous"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  </ul>
                </nav>
              </div>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setSelectedCourse(null);
                }}
              >
                Undo
              </button>
              <button type="button" className="btn btn-primary" disabled={!selectedCourse} onClick={assignCourse}>
                Assign Course
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
